import React, {useState, useEffect, useCallback} from "react";

import {
    CircularProgress,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Icon,
    Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DataPaginate } from "../../../models/utils/data-paginate";
import Pagination from "../../../components/pagination/pagination";
import GlobalSearch from "../../../components/global-search/globalSearch";
import Paper from "@material-ui/core/Paper";
import KpiContentSeparator from "../../../components/kpi-content-separator/KpiContentSeparator";
import ItemTender from "./ItemTender";
import TransferService from "../../../services/transfer-service";
import { Transfer } from "../../../models/transfer";
import { User } from "../../../models/user";
import PreviewPdf from "../../../components/preview-pdf/PreviewPdf";
import Modal from "../../../components/modal/Modal";
import HeadTableCell from "../../../components/table/HeadTableCell";
import { ErrorForm } from "../../../components/ErrorForm";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import {debounce} from "lodash";

interface IListParams {
    user: User;
    fetchKpis: () => void;
}
const ListTenders: React.FunctionComponent<IListParams> = ({
    user,
    fetchKpis,
}) => {
    const { t } = useTranslation();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [dataPaginate, setDataPaginate] = useState(new DataPaginate());
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [pdfContent, setPdfContent] = useState<any>(null);
    const [date, setDate] = useState(new Date());

    const fetchData = async () => {
        if (!loading) setLoading(true);
        try {
            const dataService = new TransferService();
            setDataPaginate(await dataService.tenders());
        } catch (error) {
            console.log(error);
            setErrorApi(new ErrorFormData());
        }
        setLoading(false);
    };

    const debounceQuery = useCallback(
        debounce(fetchData, 500),
        [currentPage, search, date]
    );

    useEffect(() => {
        debounceQuery();
        return debounceQuery.cancel;
    }, [currentPage, search, date, debounceQuery]);

    function onPageChange(newPage?: number) {
        if (newPage) {
            setLoading(true);
            setCurrentPage(newPage);
        }
    }

    const refreshDatas = () => {
        fetchData();
        fetchKpis();
    };

    function renderTablePresta() {
        return (
            <>
                <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <HeadTableCell align="center">
                                    {t("ref")}
                                </HeadTableCell>
                                <HeadTableCell
                                    align="center"
                                    className="client_logo_table"
                                >
                                    {t("client")}
                                </HeadTableCell>

                                <HeadTableCell align="center">
                                    {t("nb_passengers")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("departure_date")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("departure_time")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("estimated_arrival_time")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("type")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("departure_location")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("informations")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("arrival_location")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("note")}
                                </HeadTableCell>
                                <HeadTableCell
                                    align="center"
                                    className="status_list"
                                >
                                    {t("folder_status")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("amount")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("my_proposition")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("driver")}
                                </HeadTableCell>
                                <HeadTableCell
                                    align="center"
                                    className="action_open"
                                />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataPaginate.data.filter((transfer: Transfer) => transfer.status !== "ended" && transfer.status !== "canceled").map((transfer: Transfer) => (
                                <ItemTender
                                    key={`table_transfer_id_${transfer.id}`}
                                    transfer={transfer}
                                    onDeleteSuccess={() => refreshDatas()}
                                    user={user}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    }

    return (
        <>
            <GlobalSearch
                onSearch={(value: string) => {
                    setCurrentPage(1)
                    setSearch(value)
                }}
                onResetFilter={() => setDate(new Date())}
            />
            <KpiContentSeparator />

            {!loading && dataPaginate.data.length > 0 ? (
                <>
                    {renderTablePresta()}
                    <Pagination
                        dataPaginate={dataPaginate}
                        onPageChange={(page: any) => onPageChange(page)}
                    />
                    <Modal
                        label={t("preview_pdf")}
                        isOpen={pdfContent !== null}
                        closeModal={() => setPdfContent(null)}
                    >
                        <PreviewPdf bytes={pdfContent} />
                    </Modal>
                    <div className="refresh-transfers">
                        <Button
                            className="btn-form btn-icon"
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={() => fetchData()}
                        >
                            <span>{t("refresh")}</span> <Icon>refresh</Icon>
                        </Button>
                    </div>
                </>
            ) : loading ? (
                <CircularProgress />
            ) : (
                <div>{t("no_items")}</div>
            )}

            <ErrorForm errorApi={errorApi} />
        </>
    );
};

export default ListTenders;
