import { TextField } from "@material-ui/core";
import React from "react";

import { useTranslation } from "react-i18next";
import FormSection from "../../../components/form-section/FormSection";
import { Transfer } from "../../../models/transfer";

interface IListParams {
    transfer: Transfer;
}
const TransferRecap: React.FunctionComponent<IListParams> = ({ transfer }) => {
    const { t } = useTranslation();

    function getCivility() {
        return transfer.passengers &&
            transfer.passengers.length > 0 &&
            transfer.passengers[0].civility
            ? t(`civility_type.${transfer.passengers[0].civility}`)
            : "";
    }

    return (
        <FormSection title={t("informations")} className="mgl2">
            {transfer ? (
                <div>
                    <div className="field-form col-12 col-md-6 col-xl-2">
                        <TextField
                            disabled={true}
                            label={t("form.folder_name")}
                            value={transfer.folder?.name}
                        />
                    </div>
                    <div className="field-form col-12 col-md-6 col-xl-2">
                        <TextField
                            disabled={true}
                            label={t("main_contact")}
                            value={
                                transfer?.passengers &&
                                transfer?.passengers.length > 0
                                    ? getCivility() +
                                      " " +
                                      transfer.passengers[0]?.firstname +
                                      " " +
                                      transfer.passengers[0]?.lastname
                                    : ""
                            }
                        />
                    </div>
                    <div className="field-form col-12 col-md-6 col-xl-2">
                        <TextField
                            disabled={true}
                            label={t("departure_date")}
                            value={transfer?.departure_datetime?.toLocaleDateString()}
                        />
                    </div>
                    <div className="field-form col-12 col-md-6 col-xl-2">
                        <TextField
                            disabled={true}
                            label={t("departure_time")}
                            value={transfer?.departure_datetime?.toLocaleTimeString()}
                        />
                    </div>
                    <div className="field-form col-12 col-md-6 col-xl-2">
                        <TextField
                            disabled={true}
                            label={t("departure_location")}
                            value={transfer?.departure_location}
                        />
                    </div>
                    {transfer.vehicle_type && <div className="field-form col-12 col-md-6 col-xl-2">
                        <TextField
                            disabled={true}
                            label={t("vehicle_type")}
                            value={transfer?.vehicle_type.label}
                        />
                    </div> }
                    <div className="field-form col-12 col-md-6 col-xl-2 mgt2">
                        <TextField
                            disabled={true}
                            label={t("options")}
                            value={transfer?.options
                                ?.map((option: any) => option?.label)
                                .join(",")}
                        />
                    </div>
                    <div className="field-form col-12 col-md-6 col-xl-2">
                        <TextField
                            disabled={true}
                            label={t("form.nb_passenger")}
                            value={transfer?.nb_passenger}
                        />
                    </div>
                    <div className="field-form col-12 col-md-6 col-xl-3">
                        <TextField
                            disabled={true}
                            label={t("is_tender")}
                            value={
                                transfer.isTender()
                                    ? `${t(
                                          "form." + transfer.getTypeTender()
                                      )} (${
                                          transfer.tender_price ||
                                          transfer.min_estimate +
                                              " - " +
                                              transfer.max_estimate
                                      } ${t("money_symbol")})`
                                    : t("no")
                            }
                        />
                    </div>
                </div>
            ) : (
                ""
            )}
        </FormSection>
    );
};

export default TransferRecap;
