import React from "react";
import "./InfoCard.scss";
import { Icon } from "@material-ui/core";
import ImagePreview from "../../../../components/image-preview/ImagePreview";
import { Driver } from "../../../../models/driver";

interface Iprops {
    item: Driver;
    remove: () => void;
}

const InfoCardDrivers = ({ item, remove }: Iprops) => {
    return (
        <div className="transfer_amount_card">
            <div className="img">
                <ImagePreview
                    size="litle"
                    existingImageUrl={item?.img_profil_url}
                    onUpdateImage={() => null}
                    disabledForm
                />
            </div>
            <span className="firstname">{item.last_name}</span>
            <span className="lastname">{item.first_name}</span>
            <span className="phone">{item?.phone}</span>
            <div className="close_item" onClick={() => remove()}>
                <Icon className="close_icon">close</Icon>
            </div>
        </div>
    );
};

export default InfoCardDrivers;
