import {
    Collapse,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";
import React, { useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { User } from "../../models/user";

interface IProps{
    user: User
}
export function LeftSideNavProvider({user}: IProps) {
    const { t } = useTranslation();
    let { url } = useRouteMatch();

    return (
        <>
            <List className="asm-list asml-top">

              <Link className="side-nav-link" to={`${url}/vehicles`}>
                <ListItem button>
                    <ListItemIcon>
                        <img
                            src={require("../menu-icons/car.svg")}
                            alt="Véhicules"
                        />
                    </ListItemIcon>
                    <ListItemText primary= {t("nav.my_vehicles")}/>
                </ListItem>
               </Link>

               <Link className="side-nav-link" to={`${url}/drivers`}>
                 <ListItem button>
                    <ListItemIcon>
                        <img
                            src={require("../menu-icons/bxs-user-pin.svg")}
                            alt="les chauffeurs"
                        />
                    </ListItemIcon>
                    <ListItemText primary={t("nav.my_drivers")}/>
                  </ListItem>
                </Link>

                { user.canShowOffers() &&
                 <Link className="side-nav-link" to={`${url}/tenders`}> 
                  <ListItem button>
                    <ListItemIcon>
                        <img
                            src={require("../menu-icons/icon-adjucication.svg")}
                            alt="les chauffeurs"
                        />
                    </ListItemIcon>
                    <ListItemText primary={t("adjucication")}/>
                  </ListItem>
                 </Link>
                }

                <SubNavTransfers t={t} url={url} />
                
                <Link className="side-nav-link" to={`${url}/support`}>
                  <ListItem button>
                    <ListItemIcon>
                        <img
                            src={require("../menu-icons/help.svg")}
                            alt="Support"
                        />
                    </ListItemIcon>
                    <ListItemText primary={t("nav.support")}/>
                  </ListItem>
                </Link>

                <Link className="side-nav-link" to={`${url}/trackings`}>
                    <ListItem button>
                        <ListItemIcon>
                            <img
                            src={require("../menu-icons/tracking.svg")}
                            alt= {t("nav.tracking")}
                            />
                        </ListItemIcon>
                        <ListItemText primary={t("nav.tracking")}/>
                    </ListItem>
                </Link>

            </List>
        </>
    );
}

function SubNavTransfers({ t, url }: any) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>

            <Link className="side-nav-link" to={`${url}/transfers`}>
              <ListItem button onClick={() => setIsOpen(!isOpen)}>
                <ListItemIcon>
                    <img
                        src={require("../menu-icons/icon-transfer.svg")}
                        alt="Transferts"
                    />
                </ListItemIcon>
                <ListItemText primary={t("nav.transfers")}/>
                {isOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            </Link>

            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>

                  <Link className="side-nav-link" to={`${url}/transfers/history`}>
                    <ListItem button>
                        <ListItemIcon>
                            <img
                                src={require("../menu-icons/icon-history.svg")}
                                alt={t("history")}
                            />
                        </ListItemIcon>
                        <ListItemText primary={t("history")}/>
                    </ListItem>
                  </Link>
                  
                </List>
            </Collapse>
        </>
    );
}
