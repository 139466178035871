import React, { useState, useEffect, useCallback } from "react";
import {
    CircularProgress,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Icon,
    Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DataPaginate } from "../../../models/utils/data-paginate";
import Pagination from "../../../components/pagination/pagination";
import GlobalSearch from "../../../components/global-search/globalSearch";
import Paper from "@material-ui/core/Paper";
import KpiContentSeparator from "../../../components/kpi-content-separator/KpiContentSeparator";
import ItemTransfer from "./ItemTransfer";
import TransferService from "../../../services/transfer-service";
import { Transfer } from "../../../models/transfer";
import "./ListTransfers.scss";
import { Roles, User } from "../../../models/user";
import PreviewPdf from "../../../components/preview-pdf/PreviewPdf";
import Modal from "../../../components/modal/Modal";
import HeadTableCell from "../../../components/table/HeadTableCell";
import { ErrorForm } from "../../../components/ErrorForm";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import FitlersTransfers from "./FiltersTransfers";
import { FiltersEnabled } from "../../../models/utils/filters/filter-enabled";
import { debounce } from "lodash";
import VehicleTypeService from "../../../services/vehicle-type-service";
import { VehicleType } from "../../../models/vehicle-type";

interface IListParams {
    folderId?: number | null;
    providerId?: number | null;
    nbImports: number;
    addTransfer: () => void;
    user: User;
}

const ListTransfers: React.FunctionComponent<IListParams> = ({
    folderId,
    providerId,
    nbImports,
    addTransfer,
    user,
}) => {
    const { t } = useTranslation();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [dataPaginate, setDataPaginate] = useState(new DataPaginate());
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [pdfContent, setPdfContent] = useState<any>(null);
    const [date, setDate] = useState(new Date());
    const [filtersEnabled, setFiltersEnabled] = useState<FiltersEnabled>(
        new FiltersEnabled()
    );
    const [vehiclesTypes, setVehiclesTypes] = useState<VehicleType[]>();

    useEffect(() => {
        async function fetchVehiclesTypes() {
            const vehicleTypeService = new VehicleTypeService();
            setVehiclesTypes(await vehicleTypeService.all());
        }
        fetchVehiclesTypes();
    }, []);

    const fetchAll = async () => {
        try {
            if (!loading) setLoading(true);
            const dataService = new TransferService();
            if (providerId) {
                setDataPaginate(
                    await dataService.getTransfersOfProvider(
                        providerId,
                        currentPage,
                        search,
                        filtersEnabled
                    )
                );
            } else {
                setDataPaginate(
                    await dataService.all(
                        currentPage,
                        search,
                        filtersEnabled,
                        folderId
                    )
                );
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setErrorApi(new ErrorFormData());
        }
    };

    const debounceQuery = useCallback(debounce(fetchAll, 500), [
        currentPage,
        search,
        folderId,
        providerId,
        filtersEnabled,
        date,
    ]);

    useEffect(() => {
        debounceQuery();
        return debounceQuery.cancel;
    }, [
        currentPage,
        search,
        folderId,
        providerId,
        filtersEnabled,
        date,
        debounceQuery,
    ]);

    function onPageChange(newPage?: number) {
        if (newPage) {
            setLoading(true);
            setCurrentPage(newPage);
        }
    }

    function renderTable(){
        return (
            <>
                <TableContainer component={Paper} className="table-scroll">
                    <Table aria-label="customized table">
                        <TableHead >
                            <TableRow>
                                <HeadTableCell align="center">
                                    {t("ref")}
                                </HeadTableCell>
                                <HeadTableCell
                                    align="center"
                                    className="client_logo_table"
                                >
                                    {t("client")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("nb_passengers")}
                                </HeadTableCell>
                                <HeadTableCell
                                    align="center"
                                >
                                    {t("vehicle_type")}
                                </HeadTableCell>
                                <HeadTableCell
                                    align="center"
                                >
                                    {t("licence_number")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("departure_date")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("departure_time")}
                                </HeadTableCell>
                                <HeadTableCell align="center" width={200}>
                                    {t("departure_location")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("type")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("drivers")}
                                </HeadTableCell>

                                <HeadTableCell align="center">
                                    {t("drivers_phone")}
                                </HeadTableCell>
                                <HeadTableCell align="center" width={200}>
                                    {t("arrival_location")}
                                </HeadTableCell>
                                <HeadTableCell
                                    align="center"
                                    className="status_list"
                                >
                                    {t("folder_status")}
                                </HeadTableCell>

                                <HeadTableCell align="center">
                                    {t("last_update")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("history")}
                                </HeadTableCell>

                                <HeadTableCell align="center">
                                    {t("note")}
                                </HeadTableCell>
                            
                                <HeadTableCell align="center">
                                    {t("passengers_notified")}
                                </HeadTableCell>
                               
                                <HeadTableCell
                                    align="center"
                                    className="action_open"
                                />

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataPaginate.data &&
                                dataPaginate.data.length !== 0 &&
                                dataPaginate.data.map((transfer: Transfer) => (
                                    <ItemTransfer
                                        key={`table_transfer_id_${transfer.id}`}
                                        transfer={transfer}
                                        onDeleteSuccess={() => fetchAll()}
                                        onPreviewEstimate={(data) =>
                                            setPdfContent(data)
                                        }
                                        user={user}
                                        setError={(e: any) => setErrorApi(e)}
                                        vehiclesTypes={vehiclesTypes}
                                    />
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }

    function renderTableClient() {
        return (
            <>
                <TableContainer component={Paper} className="table-scroll">
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <HeadTableCell align="center">
                                    {t("ref")}
                                </HeadTableCell>
                                <HeadTableCell
                                    align="center"
                                    className="client_logo_table"
                                >
                                    {t("client")}
                                </HeadTableCell>
                                {/* <HeadTableCell align="center">
                                    {t("nb_passengers")}
                                </HeadTableCell> */}

                                <HeadTableCell align="center">
                                    {t("departure_date")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("departure_time")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("estimated_arrival_time")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("departure_location")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("type")}
                                </HeadTableCell>
                                {/* <HeadTableCell align="center">
                                    {t("informations")}
                                </HeadTableCell> */}
                                <HeadTableCell align="center">
                                    {t("drivers")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("drivers_phone")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("arrival_location")}
                                </HeadTableCell>

                                <HeadTableCell
                                    align="center"
                                    className="status_list"
                                >
                                    {t("folder_status")}
                                </HeadTableCell>
                                <HeadTableCell
                                    align="center"
                                    className="history_column"
                                >
                                    {t("history")}
                                </HeadTableCell>
                                <HeadTableCell align="center"></HeadTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataPaginate.data &&
                                dataPaginate.data.length !== 0 &&
                                dataPaginate.data.sort((a: any, b: any) => a.departure_datetime - b.departure_datetime).map((transfer: Transfer) => (
                                    <ItemTransfer
                                        key={`table_transfer_id_${transfer.id}`}
                                        transfer={transfer}
                                        onDeleteSuccess={() => fetchAll()}
                                        onPreviewEstimate={(data) =>
                                            setPdfContent(data)
                                        }
                                        user={user}
                                        setError={(e: any) => setErrorApi(e)}
                                        vehiclesTypes={vehiclesTypes}
                                    />
                                ))}
                        </TableBody>

                        
                    </Table>
                </TableContainer>
            </>
        );
    }

    function renderTableAdminPresta() {
        return (
            <>
                <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <HeadTableCell align="center">
                                    {t("folder_image")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("ref")}
                                </HeadTableCell>
                                <HeadTableCell
                                    align="center"
                                    className="client_logo_table"
                                >
                                    {t("client")}
                                </HeadTableCell>

                                {/* <HeadTableCell align="center">
                                    {t("passengers_name")}
                                </HeadTableCell> */}
                                {/* <HeadTableCell align="center">
                                    {t("passengers_phone")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("passengers_mail")}
                                </HeadTableCell> */}

                                <HeadTableCell align="center">
                                    {t("departure_date")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("departure_time")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("estimated_arrival_time")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("departure_location")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("type")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("informations")}
                                </HeadTableCell>

                                <HeadTableCell align="center">
                                    {t("drivers")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("drivers_phone")}
                                </HeadTableCell>

                                <HeadTableCell align="center">
                                    {t("arrival_location")}
                                </HeadTableCell>
                                <HeadTableCell
                                    align="center"
                                    className="status_list"
                                >
                                    {t("folder_status")}
                                </HeadTableCell>

                                <HeadTableCell align="center">
                                    {t("last_update")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("history")}
                                </HeadTableCell>

                                <HeadTableCell align="center">
                                    {t("note")}
                                </HeadTableCell>
                                {user.isAdmin() && (
                                    <HeadTableCell align="center">
                                        {t("passengers_notified")}
                                    </HeadTableCell>
                                )}
                                <HeadTableCell
                                    align="center"
                                    className="action_open"
                                />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataPaginate.data &&
                                dataPaginate.data.length !== 0 &&
                                dataPaginate.data.sort((a: any, b: any) => a.departure_datetime - b.departure_datetime).map((transfer: Transfer) => (
                                    <ItemTransfer
                                        key={`table_transfer_id_${transfer.id}`}
                                        transfer={transfer}
                                        onDeleteSuccess={() => fetchAll()}
                                        onPreviewEstimate={(data) =>
                                            setPdfContent(data)
                                        }
                                        user={user}
                                        setError={(e: any) => setErrorApi(e)}
                                        vehiclesTypes={vehiclesTypes}
                                    />
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    }

    return (
        <>
            <GlobalSearch
                onSearch={(value: string) => setSearch(value)}
                onResetFilter={() => setDate(new Date())}
            />
            {user.role !== Roles.CLIENT && (
                <FitlersTransfers
                    filtersEnabled={filtersEnabled}
                    setFilters={(filters) => setFiltersEnabled(filters)}
                />
            )}
            <KpiContentSeparator />

            {!loading && dataPaginate && dataPaginate.data?.length !== 0 ? (
                <>
                    { renderTable() /*user.role === Roles.CLIENT
                        ? renderTableClient()
                        : renderTableAdminPresta()*/
                        
                        }
                    <Pagination
                        dataPaginate={dataPaginate}
                        onPageChange={(page) => onPageChange(page)}
                    />
                    <Modal
                        label={t("preview_pdf")}
                        isOpen={pdfContent !== null}
                        closeModal={() => setPdfContent(null)}
                    >
                        <PreviewPdf bytes={pdfContent} />
                    </Modal>
                    <div className="refresh-transfers">
                        <Button
                            className="btn-form btn-icon"
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={() => fetchAll()}
                        >
                            <span>{t("refresh")}</span> <Icon>refresh</Icon>
                        </Button>
                    </div>
                </>
            ) : loading ? (
                <CircularProgress />
            ) : (
                <div>{t("no_items")}</div>
            )}
            {!loading &&
            (folderId || providerId) &&
            dataPaginate &&
            dataPaginate.data &&
            dataPaginate.data.length === 0 &&
            user.role !== Roles.PROVIDER ? (
                <div className="container-add-transfers">
                    <div>
                        <span>{t("success_add_folder")}</span>
                        <span>{t("add_transfer")}</span>
                    </div>

                    <Button
                        className="btn-form btn-icon"
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => addTransfer()}
                    >
                        <span>{t("add")}</span> <Icon>add</Icon>
                    </Button>
                </div>
            ) : (
                ""
            )}
            <ErrorForm errorApi={errorApi} />
        </>
    );
};

export default ListTransfers;
