import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../../components/form-title/FormTitle";
import { useForm } from "react-hook-form";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import { toastr } from "react-redux-toastr";
import { Prompt } from "react-router-dom";
import "./FormUser.scss";
import SubmitComponent from "../../../components/submit-component/SubmitComponent";
import { ErrorForm } from "../../../components/ErrorForm";
import InputControlled from "../../../components/fields-controlled/InputControlled";
import { CircularProgress, Input } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { Roles, User } from "../../../models/user";
import InputDateControlled from "../../../components/fields-controlled/InputDateControlled";
import { formatDatePicker } from "../../../utils/DateHelper";
import ContentSection from "../../../components/content-section/ContentSection";
import UserService from "../../../services/user-service";
import ImagePreviewProfile from "../../../components/image-preview/ImagePreviewProfile";
import { getUser } from "../../../redux/store/auth/actions";
import FormProvider from "../../provider/form-provider/FormProvider";
import CountryService from "../../../services/country-service";
import ProviderTypeService from "../../../services/provider-type-service";
import { ProviderType } from "../../../models/provider-type";
import { Country } from "../../../models/country";
import ProviderService from "../../../services/provider-service";
import { Provider } from "../../../models/provider";

interface IPropsFormUser {
    firstname: string;
    lastname: string;
    birth_date?: Date;
    img_base64?: string;
    email: string;
}

interface IFormProps {
    user: User;
}

function FormUser({ user }: IFormProps) {
    const { t } = useTranslation();

    const { register, handleSubmit, errors, setValue, control } =
        useForm<IPropsFormUser>();
    const dispatch = useDispatch();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [imagePreview] = useState<any>(null);
    const [urlAvatar, setUrlAvatar] = useState<string>();
    const [popupConfirmLeave, setPopupConfirmLeave] = useState(true);
    const [isActivateButton, setIsActivateButton] = useState(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [providerTypes, setProviderTypes] = useState<Array<ProviderType>>([]);
    const [countries, setCountries] = useState<Array<Country>>([]);
    const [provider, setProvider] = useState<Provider>();

    useEffect(() => {
        const fetchAll = async () => {
            try {
                if (user.role === Roles.PROVIDER) {
                    setLoading(true);
                    const countryService = new CountryService();
                    setCountries(await countryService.all());
                    const providerTypeService = new ProviderTypeService();
                    setProviderTypes(await providerTypeService.all());
                    if (user.provider) {
                        const providerService = new ProviderService();
                        setProvider(
                            await providerService.get(user.provider.id)
                        );
                    }

                }
            } catch (error) {
                setErrorApi(new ErrorFormData(error.response.data));
            }
            setLoading(false);
        };
        fetchAll();
    }, [user]);

    const onSubmit = async (data: IPropsFormUser) => {
        setIsActivateButton(false);
        setErrorApi(new ErrorFormData());
        const service = new UserService();
        try {
            await service.update(data);
            dispatch(getUser());
            toastr.success(t("success"), t("success_update_user"));
            setPopupConfirmLeave(false);
        } catch (error) {
            setErrorApi(new ErrorFormData(error.response.data));
        }
        setIsActivateButton(true);
    };

    return (
        <>
            <ContentSection title={t("informations")} returnAction={true}>
                {loading && <CircularProgress />}
                {!!user &&
                    user?.role === Roles.PROVIDER &&
                    !!provider &&
                    !!countries &&
                    !!providerTypes && (
                        <FormProvider
                            countries={countries}
                            provider={provider}
                            providerTypes={providerTypes}
                        />
                    )}
                {!user ||
                    (user?.role !== Roles.PROVIDER && (
                        <>
                            <Prompt
                                when={popupConfirmLeave}
                                message={`${t("lost_input")}\n${t(
                                    "sure_to_back"
                                )}`}
                            />
                            <form
                                className="form-react-provider"
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <FormTitle title={t("form.profile")} />
                                <div className="provider-profile">
                                    <div className="row row-md">
                                        <div className="col-12 col-md-6 col-lg">
                                            <div className="form-group">
                                                <InputControlled
                                                    name="firstname"
                                                    label={`${t(
                                                        "form.firstname"
                                                    )} *`}
                                                    value={user?.firstname}
                                                    required={true}
                                                    disabled={false}
                                                    control={control}
                                                    errors={errors}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg">
                                            <div className="form-group">
                                                <InputControlled
                                                    name="lastname"
                                                    label={`${t(
                                                        "form.lastname"
                                                    )} *`}
                                                    value={user?.lastname}
                                                    required={true}
                                                    disabled={false}
                                                    control={control}
                                                    errors={errors}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg">
                                            <div className="form-group">
                                                <label className="phone-number-label">
                                                    {t("form.img_avatar")} (max: 2Mo)
                                                </label>
                                                <Input
                                                    className="input-file-url"
                                                    value={urlAvatar}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg">
                                            <div className="form-group">
                                                <div className="flex_row">
                                                    <input
                                                        type="hidden"
                                                        name="img_base64"
                                                        ref={register()}
                                                    />
                                                    <ImagePreviewProfile
                                                        disabledForm={false}
                                                        existingImageUrl={
                                                            user.avatar_url
                                                        }
                                                        canUpdate={true}
                                                        onUpdateImage={(
                                                            img_base64,
                                                            file
                                                        ) => {
                                                            setValue(
                                                                "img_base64",
                                                                img_base64
                                                            );
                                                            setUrlAvatar(
                                                                file[0].name
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {imagePreview ? (
                                                <div className="preview_image">
                                                    <img
                                                        src={imagePreview}
                                                        alt=""
                                                    />
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="provider-profile">
                                    <div className="row row-md">
                                        <div className="col-12 col-md">
                                            <div className="form-group">
                                                <InputControlled
                                                    name="email"
                                                    label={`${t(
                                                        "form.email"
                                                    )} *`}
                                                    value={user?.email}
                                                    required={true}
                                                    disabled={false}
                                                    control={control}
                                                    errors={errors}
                                                />
                                            </div>
                                        </div>
                                        {/*
                            <div className="col">
                                <div className="form-group">
                                    <div className="phone-number-input">
                                        <label className="phone-number-label">{t("form.phone")}</label>
                                        <CountryPhoneControlled
                                            name="mobileNumber"
                                            label={t("form.mobile_number")}
                                            value={user.mobile_number}
                                            required={false}
                                            disabled={false}
                                            control={control}
                                            errors={errors}
                                        />
                                    </div>
                                </div>
                            </div>
                            */}
                                        <div className="col-12 col-md">
                                            <div className="form-group">
                                                <InputDateControlled
                                                    name="birth_date"
                                                    label={t("form.birth_date")}
                                                    value={formatDatePicker(
                                                        user.birth_date
                                                    )}
                                                    disabled={false}
                                                    control={control}
                                                    errors={errors}
                                                    required={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <SubmitComponent
                                    text={t("form.validate")}
                                    isActivate={isActivateButton}
                                />

                                <ErrorForm errorApi={errorApi} />
                            </form>
                        </>
                    ))}
            </ContentSection>
        </>
    );
}

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(FormUser);
