import React, { useEffect, useState } from "react";
import FormSection from "../../../../components/form-section/FormSection";
import InputControlled from "../../../../components/fields-controlled/InputControlled";
import { Transfer } from "../../../../models/transfer";
import { useTranslation } from "react-i18next";
import AutocompleteControlled from "../../../../components/fields-controlled/AutocompleteControlled";
import { Driver } from "../../../../models/driver";
import InfoCardDrivers from "../InfoCards/InfoCardDrivers";
import SubFormOptions from "../SubFormOptions/SubFormOptions";
import InfoCardOptions from "../InfoCards/InfoCardOptions";
import { VehicleOption } from "../../../../models/vehicle-option";
import { TransferResponseOption } from "../../../../models/transfer_response";
import { Assignment } from "../../../../models/assignment";
import { TextareaAutosize } from "@material-ui/core";
import { Vehicle } from "../../../../models/vehicle";

interface Iprops {
    transfer: Transfer;
    setResponseOptions: any;
    responseOptions: any;
    drivers: Driver[];
    setValue: (e: any, e2: any) => void;
    getValues: (e: any) => void;
    control: any;
    errors: any;
    disabledForm: any;
    assignment?: Assignment;
    vehicles: Vehicle[]
}

const FormProposition = ({
    setValue,
    transfer,
    responseOptions,
    setResponseOptions,
    getValues,
    control,
    errors,
    disabledForm,
    drivers,
    assignment,
    vehicles
}: Iprops) => {
    console.log(vehicles)
    const { t } = useTranslation();
    const [options, setOptions] = useState<VehicleOption[]>([]);
    const [driverChoice, setDriverChoice] = useState<Driver | null>();
    const [vehicleChoice, setVehicleChoice] = useState<Vehicle | null>();
    const [selectedOption, setSelectedOption] = useState<any>();
    const data: any = getValues("option");

    const updateDriver = (selected: any) => {
        setDriverChoice(selected);
        setValue("driver_id", selected.id);
    };
    const removeDriver = () => {
        setDriverChoice(null);
        setValue("driver_id", null);
        setValue("driver", null);
    };
    const updateVehicle = (selected: any) => {
        setVehicleChoice(selected);
        setValue("vehicle_id", selected.id);
    };
    useEffect(() => {
        if (transfer.options && transfer.options.length > 0) {
            let defaultOptions: any = transfer.options;
            if (responseOptions.length > 0) {
                responseOptions.forEach((r: TransferResponseOption) => {
                    defaultOptions =
                        transfer.options &&
                        transfer.options.filter(
                            (o: VehicleOption) => o.id !== r.id
                        );
                });
            }

            setOptions(defaultOptions);
        }
    }, [responseOptions, transfer.options]);
    const removeValidatedOption = (response: any) => {
        setResponseOptions(
            responseOptions.filter(
                (r: TransferResponseOption) => r.id !== response.id
            )
        );
        if (transfer.options) {
            setOptions([
                ...options,
                transfer.options.filter((o: any) => o.id === response.id)[0],
            ]);
        }
    };

    const saveAmountOption = () => {
        const id = Number(getValues("option_id"));
        const price = Number(getValues("price"));
        const tva = Number(getValues("tva"));
        const label = data?.label;
        const responseOption = new TransferResponseOption({
            id: id,
            price: price,
            tva: tva,
            label: label,
        });

        setResponseOptions([...responseOptions, responseOption]);
        if (options) {
            setOptions([
                ...options.filter((o: any) => o.id !== selectedOption.id),
            ]);
        }
        setSelectedOption(null);
    };

    // const [smsContent, setSmsContent] = useState<string>(assignment?.response && assignment.response.sms_detail ? assignment.response.sms_detail.trim() : "");
    // const [counter, setCounter] = useState<number>(0);

    // useEffect(() => {
    //     setCounter(smsContent ? smsContent.length : 0);
    //     setValue("sms_detail", smsContent);
    // }, [smsContent]);

    return (
        <div className="form_row">
            <div className="response col-4">
                <FormSection title={t("your_response")}>
                    <div className="field_price_section">
                        <div className="field-form field_price">
                            <InputControlled
                                name="ht_price"
                                label={t("form.ht_price")}
                                value={
                                    assignment?.response
                                        ? assignment.response.price
                                        : ""
                                }
                                required={true}
                                disabled={false}
                                control={control}
                                errors={errors}
                                decorator={
                                    <span className="decorator">
                                        {t("money_symbol")}
                                    </span>
                                }
                            />
                        </div>
                        {/*<div className="field-form field_price">
                            <InputControlled
                                name={"tva"}
                                label={t("form.tva")}
                                value={20}
                                required={true}
                                disabled={false}
                                control={control}
                                errors={errors}
                                decorator={<span className="decorator">%</span>}
                            />
                            </div>*/}
                    </div>

                    {drivers && (
                        <AutocompleteControlled
                            label={t("drivers")}
                            name="driver"
                            disabled={disabledForm}
                            listItems={drivers ? drivers : []}
                            value={
                                driverChoice
                                    ? driverChoice
                                    : assignment?.response?.driver
                            }
                            required={true}
                            getOptionLabel={(option: Driver) =>
                                option.last_name + " " + option.first_name
                            }
                            control={control}
                            errors={errors}
                            onChange={(driver: any) => updateDriver(driver)}
                        />
                    )}
                    {vehicles && (
                        <div style={{marginTop: 10}}>
                            <AutocompleteControlled
                                label={t("vehicles")}
                                name="vehicle"
                                disabled={disabledForm}
                                listItems={vehicles ? vehicles : []}
                                value={vehicleChoice}
                                required={true}
                                getOptionLabel={(option: Vehicle) =>
                                    option.brand?.name + " " + option.model?.label + " " + option.year
                                }
                                control={control}
                                errors={errors}
                                onChange={(vehicle: any) => updateVehicle(vehicle)}
                            />
                        </div>
                    )}
                    {driverChoice && (
                            <InfoCardDrivers
                                item={driverChoice}
                                remove={removeDriver}
                            />
                    )}
                </FormSection>
            </div>
            <div className="options col-4">
                <FormSection title={t("options")}>
                    <div className="options_section">
                        <div className="options_content">
                            {transfer.options ? (
                                options.length > 0 ? (
                                    <AutocompleteControlled
                                        label={t("vehicle_options")}
                                        name="option"
                                        disabled={disabledForm}
                                        listItems={options}
                                        value={
                                            selectedOption
                                                ? selectedOption
                                                : null
                                        }
                                        required={true}
                                        getOptionLabel={(
                                            option: VehicleOption
                                        ) => option.label}
                                        control={control}
                                        errors={errors}
                                        onChange={(option: any) =>
                                            setSelectedOption(option)
                                        }
                                    />
                                ) : (
                                    t("every_options_selected")
                                )
                            ) : (
                                t("transfer_has_not_options")
                            )}

                            {selectedOption && (
                                <div className="subform_option_section">
                                    <div className="subform_options">
                                        <SubFormOptions
                                            disabled={disabledForm}
                                            control={control}
                                            errors={errors}
                                            onAdd={() => saveAmountOption()}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="card_options">
                        {responseOptions?.map(
                            (
                                response: TransferResponseOption,
                                index: Number
                            ) => (
                                <div
                                    key={
                                        "transfer_option" +
                                        "_" +
                                        index +
                                        "_" +
                                        response?.current_time
                                    }
                                >
                                    <InfoCardOptions
                                        item={response}
                                        remove={removeValidatedOption}
                                    />
                                </div>
                            )
                        )}
                    </div>
                </FormSection>
            </div>
            {/* <div className="sms col-4">
                <FormSection title={t("sms_details")}>
                    <div className="form_row align-items-end">
                        <TextareaAutosize
                            disabled={disabledForm}
                            onChange={(e) => setSmsContent(e.target.value)}
                            value={smsContent}
                            className="textarea col-8"
                            aria-label="empty textarea"
                            rowsMin={4} rowsMax={4}
                            maxLength={40}
                            placeholder={t('comment')}
                        />
                        <span className="col-4">{counter}/40</span>
                    </div>
                </FormSection>
            </div> */}
        </div>
    );
};

export default FormProposition;
