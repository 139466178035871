import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {ErrorFormData} from "../../../../models/interfaces/errorForm";
import {ErrorForm} from "../../../../components/ErrorForm";
import {toastr} from 'react-redux-toastr'
import {FormControl, Grid, InputLabel, MenuItem, Select} from "@material-ui/core";
import InputControlled from "../../../../components/fields-controlled/InputControlled";
import FormTitle from "../../../../components/form-title/FormTitle";
import SubmitComponent from "../../../../components/submit-component/SubmitComponent";
import VehicleColorService from "../../../../services/vehicle-color-service";
import ImagePreview from "../../../../components/image-preview/ImagePreview";
import {Controller} from "react-hook-form";
import './FormColor.scss';

interface IFormModel {
    color: string;
    type: boolean;
    hex_color: string;
    img_base64: any;
}

interface IFormProps {
    onAddSuccess: () => void,
}

const FormColor: React.FunctionComponent<IFormProps> = ({onAddSuccess}) => {
    const {register, handleSubmit, errors, setValue, control, watch} = useForm<IFormModel>();
    const {t} = useTranslation();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [disabledForm, setDisabledForm] = useState(false);
    const [imagePreview, setImagePreview] = useState<any>(null);
    const [isActivateButton, setIsActivateButton] = useState(true)
    const textAdd = t('add');

    const watchHex = watch('hex_color')
    const onSubmit = async (data: any, e: any) => {
        setErrorApi(new ErrorFormData());
        const vehicleColorService = new VehicleColorService();
        try {
            setIsActivateButton(false)
            setDisabledForm(true);

            await vehicleColorService.add(data);
            onAddSuccess();
            toastr.success(t('success'), t('success_add_model'));
            setDisabledForm(false);
        } catch (error) {
            setDisabledForm(false);
            setErrorApi(new ErrorFormData(error.response.data));
        }
        setIsActivateButton(true)
    };

    return (
        <>
            <FormTitle title={t("color_add")} margin={true}/>
            <form className="form-react" onSubmit={handleSubmit(onSubmit)}>
                <Grid className="col-inputs" xs={12}>
                    <div className="field-form input-color mgr1 mgl2">
                        <InputControlled
                            name="color"
                            label={t('color')}
                            value=""
                            required={true}
                            disabled={disabledForm}
                            control={control}
                            errors={errors}
                        />
                    </div>
                    <FormControl className="field-form">
                        <InputLabel className="mgl2">{t('type')}</InputLabel>
                        <Controller
                            as={
                                <Select>
                                    <MenuItem value={0}>{t('interior')}</MenuItem>
                                    <MenuItem value={1}>{t('exterior')}</MenuItem>
                                </Select>
                            }
                            name="type"
                            defaultValue="0"
                            className="color-type-selector mgl2"
                            required={true}
                            disabled={disabledForm}
                            control={control}
                        />
                    </FormControl>
                </Grid>
                <Grid className="col-inputs" xs={12}>
                    <div className="field-form input-hex-color mgr1">
                        <InputControlled
                            name="hex_color"
                            label={t('hex_color')}
                            value=""
                            required={true}
                            disabled={disabledForm}
                            control={control}
                            errors={errors}
                        />
                    </div>
                    <div>
                        <span style={{backgroundColor: watchHex, height: 50, width: 50, display: "flex", borderRadius: 25, border: !watchHex ? "1px solid black" : "none"}}></span>
                    </div>
                </Grid>
                <SubmitComponent loading={false} text={textAdd} icon="add" mainClass="vehicle-details-submit"
                                 isActivate={isActivateButton}/>

                {errors.color && <p>{errors.color.message}</p>}
                <ErrorForm errorApi={errorApi}/>
            </form>
        </>
    )

};

export default FormColor;
