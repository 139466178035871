import { Deserializable } from "./interfaces/deserializable";
import { Provider } from "./provider";
import { VehicleType } from "./vehicle-type";
import { VehicleBrand } from "./vehicle-brand";
import { VehicleModel } from "./vehicle-model";
import { VehicleOption } from "./vehicle-option";
import { InteriorColor } from "./interior-color";
import { ExteriorColor } from "./exterior-color";

export class Vehicle implements Deserializable {
    public id: number;
    public vehicle_type_id: number;
    public brand_id: number;
    public model_id: number;
    public year: number;
    public seats_available: number;
    public licence_plate: string;
    public interior_type: number;
    public interior_color_id: number;
    public exterior_color_id: number;
    public provider_id: number;
    public provider?: Provider | null;
    public type?: VehicleType | null;
    public brand?: VehicleBrand | null;
    public model?: VehicleModel | null;
    public interior_color?: InteriorColor | null;
    public exterior_color?: ExteriorColor | null;
    public options?: Array<VehicleOption> | null;
    public attestation_url?: string | null;
    public created_at: Date;
    public updated_at: Date;

    constructor(data:any){
        this.id = data.id;
        this.vehicle_type_id = data.vehicle_type_id;
        this.brand_id = data.brand_id;
        this.model_id = data.model_id;
        this.year = data.year;
        this.seats_available = data.seats_available;
        this.licence_plate = data.licence_plate;
        this.interior_type = data.interior_type;
        this.provider_id = data.provider_id;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
        this.interior_color_id = data.interior_color_id;
        this.exterior_color_id = data.exterior_color_id;
        this.provider_id = data.provider_id;
        this.provider = data.provider ? new Provider(data.provider) : null;
        this.type = data.type ? new VehicleType(data.type): null;
        this.brand = data.brand ? new VehicleBrand(data.brand): null;
        this.model = data.model ? new VehicleModel(data.model): null;
        this.interior_color = data.interior_color ? new InteriorColor(data.interior_color): null;
        this.exterior_color = data.exterior_color ? new ExteriorColor(data.exterior_color): null;
        this.options = data.options ? data.options.map( (option: VehicleOption) => new VehicleOption(option) ) : null;
        this.attestation_url = data.attestation_url;
    }

    deserialize(input: any):this{
        return Object.assign(this, input);
    }

    getIconInteriorType() {
        if(this.interior_type === 1) {
            return 'images/interior-types/leather-type.svg';
        } else if(this.interior_type === 2) {
            return 'images/interior-types/textile-type.svg';
        }
        return ''
    }
}