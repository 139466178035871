import React, { useCallback, useEffect, useState } from "react";
import { NotificationUser } from "../../../models/notificationUser";
import {
    Checkbox,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import HeadTableCell from "../../../components/table/HeadTableCell";
import ItemTableNotification from "./ItemTableNotification";
import { handleCheckBoxChange } from "../../../utils/HandleCheckboxs";
import { DataPaginate } from "../../../models/utils/data-paginate";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import { FiltersEnabled } from "../../../models/utils/filters/filter-enabled";
import { debounce } from "lodash";
import GlobalSearch from "../../../components/global-search/globalSearch";
import FiltersNotifications from "./FiltersNotifications/FiltersNotifications";
import Pagination from "../../../components/pagination/pagination";
import { ErrorForm } from "../../../components/ErrorForm";
import NotificationService from "../../../services/notification-service";
import ActionsSection from "../../../components/actions-section/ActionsSection";
import { toastr } from "react-redux-toastr";
import * as notificationHelper from "../../../utils/NotificationHelper";
import { useDispatch } from "react-redux";
import { Checkboxes } from "../../../models/utils/checkboxes";

const ListNotifications = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [checkboxs, setCheckboxs] = useState(
        new Checkboxes({
            checkAll: false,
            selections: [],
        })
    );
    const [dataPaginateNotifications, setDataPaginateNotifications] = useState(
        new DataPaginate()
    );
    const [pageNotifications, setPageNotifications] = useState(1);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState<string | null>(null);
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [date, setDate] = useState(new Date());
    const [action, setAction] = useState("mark_as_read");
    const [filtersEnabled, setFiltersEnabled] = useState<FiltersEnabled>(
        new FiltersEnabled()
    );

    const fetchAllNotifications = async () => {
        if (!loading) setLoading(true);
        try {
            const notificationService = new NotificationService();
            setDataPaginateNotifications(
                await notificationService.getAll(
                    pageNotifications,
                    search,
                    null,
                    filtersEnabled
                )
            );
        } catch (e) {
            setErrorApi(new ErrorFormData());
        }
        setLoading(false);
    };

    const debounceQuery = useCallback(debounce(fetchAllNotifications, 500), [
        pageNotifications,
        search,
        filtersEnabled,
        date,
    ]);

    useEffect(() => {
        debounceQuery();
        return debounceQuery.cancel;
    }, [pageNotifications, search, filtersEnabled, date, debounceQuery]);

    const handleFiltersChange = (filters: FiltersEnabled) => {
        setFiltersEnabled(filters);
        setPageNotifications(1);
    };

    const handleCheckboxs = (event: any) => {
        handleCheckBoxChange(
            event,
            checkboxs,
            setCheckboxs,
            dataPaginateNotifications
        );
    };

    //Change provider actions
    const handleActionChange = (event: any) => {
        setAction(event.target.value);
    };

    //Handle validate provider action
    const validateAction =  () => {
       
        // Change state of notification (is_active)
        checkboxs.selections.length >= 1 ?

        notificationHelper.editNotificationsByCheckBoxes(
            dataPaginateNotifications,
            checkboxs,
            dispatch,
            !(action === "mark_as_read")
        )   
            ? toastr.success(t("success"), (action === "mark_as_read") ? t("success_notifications_read") : t("success_notifications_unread"))
            : toastr.error(t("error"), t("unexpected_error"))
        : toastr.warning(t("warning"), t("warning_notifications_read"))
        setCheckboxs(new Checkboxes({
            checkAll: false,
            selections: [],
        }))
        setDate(new Date())
    };

    return (
        <>
            <GlobalSearch
                onSearch={(value: string) => {
                    setPageNotifications(1);
                    setSearch(value);
                }}
                onResetFilter={() => setDate(new Date())}
            />
            <FiltersNotifications
                colorFilters={"list"}
                filtersEnabled={filtersEnabled}
                setFilters={(filters) => handleFiltersChange(filters)}
            />
            {dataPaginateNotifications &&
            dataPaginateNotifications.data?.length !== 0 &&
            !loading ? (
                <>
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            {/* HEAD */}
                            <TableHead>
                                <TableRow>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {/* Checkbox to check all items */}
                                        {dataPaginateNotifications.data !==
                                            undefined &&
                                        dataPaginateNotifications.data.length >
                                            1 ? (
                                            <Checkbox
                                                onChange={(e) =>
                                                    handleCheckboxs(e)
                                                }
                                                id="checkAll"
                                                checked={
                                                    checkboxs.checkAll || false
                                                }
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("actions")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("date")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("hour")}
                                    </HeadTableCell>
                                    <HeadTableCell
                                        className="testTh"
                                        align="center"
                                    >
                                        {t("view")}
                                    </HeadTableCell>
                                </TableRow>
                            </TableHead>
                            {/* BODY */}
                            <TableBody>
                                {dataPaginateNotifications.data.map(
                                    (notif: NotificationUser) => (
                                        <ItemTableNotification
                                            key={`table-notification-${notif.id}`}
                                            notif={notif}
                                            checkboxs={checkboxs}
                                            handleCheckbox={(e) =>
                                                handleCheckboxs(e)
                                            }
                                        />
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination
                        dataPaginate={dataPaginateNotifications}
                        onPageChange={(page: number) =>
                            setPageNotifications(page)
                        }
                    />
                    {/* DropdownListe actions + validate button */}
                    <ActionsSection
                        onChangeDropdown={(e) => handleActionChange(e)}
                        valueDropdown={action}
                        labelDropdown={t("actions")}
                        onClickButton={validateAction}
                    >
                        <option value="mark_as_read">{t("mark_as_read")}</option>
                        <option value="mark_as_unread">{t("mark_as_unread")}</option>
                    </ActionsSection>
                </>
            ) : loading ? (
                <CircularProgress />
            ) : !errorApi || dataPaginateNotifications.data?.length === 0 ? (
                <div>{t("no_items")}</div>
            ) : (
                ""
            )}
            <ErrorForm errorApi={errorApi} />
        </>
    );
};

export default ListNotifications;
