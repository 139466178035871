import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ActionTable from "../../../components/action-table/ActionTable";
import { toastr } from "react-redux-toastr";
import { Link, useHistory } from "react-router-dom";
import { Passenger, Transfer } from "../../../models/transfer";
import TransferService from "../../../services/transfer-service";
import CheckPermissions from "../../../utils/CheckPermissions";
import { Roles, User } from "../../../models/user";
import BodyTableRow from "../../../components/table/BodyTableRow";
import BodyTableCell from "../../../components/table/BodyTableCell";
import { ReactSVG } from "react-svg";
import InfoClient from "../../../components/popups/info-client/InfoClient";
import NoProviderAssigned from "../../../components/popups/no-provider-assigned/NoProviderAssigned";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import ModalUnassignment from "./modal-unassignment/ModalUnassignment";
import { Assignment } from "../../../models/assignment";
import TransferNote from "../../../components/popups/transfer-note/TransferNote";
import ModalHistory from "./modal-history/ModalHistory";
import ModalDelete from "../../../components/modal/ModalDelete";
import { Button, Icon, Tooltip, withStyles } from "@material-ui/core";
import WhiteButton from "../../../components/white-button/WhiteButton";
import { formatDateDDMMYYYY } from "../../../utils/DateHelper";
import Modal from "../../../components/modal/Modal";
import RecapFolder from "../form-transfer-amount/RecapFolder/RecapFolder";
import { useForm } from "react-hook-form";
import { VehicleType } from "../../../models/vehicle-type";
import Colors from "../../design-dependencies/Colors";
import "./ItemTransfer.scss";
import ImagePreview from "../../../components/image-preview/ImagePreview";
import LicencePlate from "../../../components/licence-plate/licencePlate";

interface IItemTransfer {
    transfer: Transfer;
    onDeleteSuccess: () => void;
    onPreviewEstimate: (data: any) => void;
    setError: (e: any) => void;
    user: User;
    vehiclesTypes?: VehicleType[];
}

const ItemTransfer = ({
    transfer,
    onDeleteSuccess,
    onPreviewEstimate,
    setError,
    user,
    vehiclesTypes,
}: IItemTransfer) => {
    const history = useHistory();
    const service = new TransferService();
    const { t } = useTranslation();
    const [showClient, setShowClient] = useState(false);
    const [showNoProvider, setShowNoProvider] = useState(false);
    const [showDecision, setShowDecision] = useState<boolean>(false);
    const [showUnasign, setShowUnassign] = useState<boolean>(false);
    const [showHistory, setShowHistory] = useState<boolean>(false);
    const [assignment, setAssignment] = useState<Assignment>();
    const [isOpen, setIsOpen] = useState(false);
    const [loaderPassenger, setLoaderPassenger] = useState(false);
    const [showModalTransferConfirmation, setShowModalTransferConfirmation] =
        useState<boolean>(false);
    const [currentVehiculeType, setCurrentVehiculeType] =
        useState<VehicleType>();

    const [showNote, setShowNote] = useState(false);
    const { handleSubmit, errors, control, setValue, register, getValues } =
        useForm<any>();

    useEffect(() => {
        if (vehiclesTypes && vehiclesTypes.length) {
            console.log(vehiclesTypes);
            setCurrentVehiculeType(
                vehiclesTypes.find(
                    (vehicleType: VehicleType) =>
                        vehicleType.id === transfer.vehicle_type_id
                )
            );
        }
    }, [vehiclesTypes]);

    useEffect(() => {
        if (user.isProvider() && transfer.canReassignDriver()) {
            const getResponse = async () => {
                const service = new TransferService();
                const assign = await service.assignments(transfer.id);
                setAssignment(assign[0]);
            };
            getResponse();
        }
    }, [user, transfer]);
    const deleteClient = async () => {
        await service.delete(transfer.id);
        setIsOpen(false);
        toastr.success(t("success"), t("success_deleted_transfer"));
        onDeleteSuccess();
    };

    const decisionTransfer = async (accept = true) => {
        try {
            if (!accept) {
                await service.accept(transfer.id, { response: null });
                toastr.success(t("success"), t("success_refuse_transfer"));
            } else {
                history.push(`/app/transfer/${transfer.id}/amount`);
            }
        } catch (error) {
            setError(new ErrorFormData(error.response.data));
        }
    };

    const canDelete = () => {
        if (user.isClient()) {
            if (
                !transfer?.folder?.client ||
                user.isReader(transfer.folder.client)
            ) {
                //client undefined or reader
                return false;
            }
        }
        return true;
    };

    const getDriversName = (transfer: Transfer) => {
        let driversName: string = "";
        transfer?.assignments?.map((assignment: Assignment) => {
            if (assignment?.response?.driver)
                driversName = `${driversName}${assignment.response.driver.last_name} ${assignment.response.driver.first_name} \n`;
        });
        return driversName === "" ? "-" : driversName;
    };

    const getDriversPhone = (transfer: Transfer) => {
        let driversPhone: string = "";
        transfer?.assignments?.map((assignment: Assignment) => {
            if (assignment?.response?.driver)
                driversPhone = `${driversPhone}+${assignment.response.driver.phone} \n`;
        });
        return driversPhone === "" ? "-" : driversPhone;
    };

    async function preventPassengers() {
        const service = new TransferService();
        try {
            setLoaderPassenger(true);
            await service.preventPassenger(transfer.id);
            transfer.passengers_prevent_at = new Date();
            setLoaderPassenger(false);
            toastr.success(t("success"), t("success_prevent_passengers"));
        } catch (error) {
            setLoaderPassenger(false);
            toastr.error(t("error"), t("error_prevent_passengers"));
        }
    }

    async function cancelUpdatedTransfer() {
        const service = new TransferService();
        /*await service.updateTender(transfer.id, {
            status: "refused",
        });*/
        await service.unassign(transfer.id, {
            comment: "Modification de transfert refusé",
            provider_id: user.provider?.id,
        });

        onDeleteSuccess();
    }

    async function acceptUpdatedTransfer() {
        const service = new TransferService();
        await service.acceptUpdatedTransfer(transfer.id);

        onDeleteSuccess();
    }

    function getAcceptedAssignment() {
        return transfer.assignments?.find( (assignment: Assignment) =>  assignment.isAccepted())
    }

    const renderItem = () => {
        return (
            <BodyTableRow>
                <BodyTableCell align="center">
                    <div className="upload-image-table">
                        {/* <Button style={{minHeight: 40, minWidth: 160}} className="btn-form btn-icon mr-2 submit-input" variant="contained" color="primary"
                            size="large" target="_blank" href={transfer?.folder?.logo_url || ''} download={true}>
                            <span>{t('download')}</span>
                            <img src="/images/app_icons/icon-download.png" alt="download"/>
                        </Button> */}
                        <ImagePreview
                        onClick={() => {window.open(transfer?.folder?.logo_url || '', '_blank')}}
                            size="litle"
                            existingImageUrl={transfer?.folder?.logo_url}
                            onUpdateImage={() => null}
                            disabledForm
                        />
                    </div>
                    {user.isClient() &&  <>
                        <div
                            className="link-table"
                            onClick={() =>
                                history.push(`/app/transfer/${transfer.id}/edit`)
                            }
                        >
                            {transfer.code}
                        </div>
                        <div
                            className="link-table"
                            onClick={() =>
                                history.push(
                                    `/app/folder/${transfer.folder?.id}/edit`
                                )
                            }
                        >
                            {transfer.folder?.code}
                        </div> 
                    </>}
                    {user.isProvider() && (
                            <>
                                {!transfer.is_confirmed && (
                                    <HtmlTooltip
                                        title={
                                            <div
                                                className="tooltip"
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <strong
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    Le transfert a été modifié
                                                </strong>
                                                <span
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    Veuillez valider votre
                                                    demande
                                                </span>
                                            </div>
                                        }
                                    >
                                        <div
                                            onClick={() =>
                                                setShowModalTransferConfirmation(
                                                    true
                                                )
                                            }
                                        >
                                            <ReactSVG
                                                beforeInjection={(svg) => {
                                                    svg.setAttribute(
                                                        "style",
                                                        "width: 25px"
                                                    );
                                                    svg.setAttribute(
                                                        "style",
                                                        "height: 25px"
                                                    );
                                                }}
                                                src="/images/app_icons/exclamation-mark.svg"
                                            />
                                        </div>
                                    </HtmlTooltip>
                                )}

                                <div>{transfer.code}</div>
                                <div>{transfer.folder?.code}</div>
                            </>
                        )}
                        {user.isAdmin() && (
                            <>
                                {!transfer.is_confirmed && (
                                    <HtmlTooltip
                                        title={
                                            <div
                                                className="tooltip"
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <strong
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    Le transfert a été modifié
                                                </strong>
                                                <span
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    En attente de la validation
                                                    du prestataire
                                                </span>
                                            </div>
                                        }
                                    >
                                        <ReactSVG
                                            beforeInjection={(svg) => {
                                                svg.setAttribute(
                                                    "style",
                                                    "width: 25px"
                                                );
                                                svg.setAttribute(
                                                    "style",
                                                    "height: 25px"
                                                );
                                            }}
                                            src="/images/app_icons/exclamation-mark.svg"
                                        />
                                    </HtmlTooltip>
                                )}

                                <div
                                    className="link-table"
                                    onClick={() =>
                                        history.push(
                                            `/app/transfer/${transfer.id}/edit`
                                        )
                                    }
                                >
                                    {transfer.code}
                                </div>
                                <div
                                    className="link-table"
                                    onClick={() =>
                                        history.push(
                                            `/app/folder/${transfer.folder?.id}/edit`
                                        )
                                    }
                                >
                                    {transfer.folder?.code}
                                </div>
                            </>
                        )}
                </BodyTableCell>
                <BodyTableCell align="center" className="client_cell">
                    {transfer.canShowClientOnTransfer() ||
                    user.isAdmin() ? (
                        <ReactSVG
                            src="/images/app_icons/user.svg"
                            className="svg_client"
                            onMouseEnter={() => setShowClient(true)}
                            onMouseLeave={() => setShowClient(false)}
                        />
                    ) : (
                        <></>
                    )}
                    {showClient && transfer?.folder && (
                        <InfoClient transfer={transfer} />
                    )}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {transfer.nb_passenger || "-"}
                </BodyTableCell>

                <BodyTableCell align="center">
                    {transfer.vehicle_type ? (
                        <div className="item-type">
                            {transfer.vehicle_type.getIcon() && <img

                                src={transfer.vehicle_type.getIcon()}
                                alt="Vehicle type"
                            /> }
                            <span>{transfer.vehicle_type.label}</span>
                        </div>
                    ) : (
                        "-"
                    )}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {user.role === Roles.PROVIDER && <>
                        { assignment?.response?.vehicle ? assignment.response.vehicle.licence_plate : '-'}
                    </>}
                    {user.role !== Roles.PROVIDER && <>
                        {getAcceptedAssignment()  ?  getAcceptedAssignment()?.response?.vehicle?.licence_plate : '-'}
                    </>}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {transfer.departure_datetime?.toLocaleDateString()}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {transfer.getHourDeparture()}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {transfer.departure_location}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {t(`transfer_type.${transfer.type}`)}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {getDriversName(transfer)}
                </BodyTableCell>

                <BodyTableCell align="center">
                    {getDriversPhone(transfer)}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {transfer.arrival_location}
                </BodyTableCell>

                <CheckPermissions permissions={[Roles.CLIENT]}>
                    <BodyTableCell align="center">
                        <div
                            className="tag-table-section"
                            onMouseEnter={() => setShowDecision(true)}
                            onMouseLeave={() => setShowDecision(false)}
                        >
                            {transfer.status === "pending" ? (
                                !showDecision ? (
                                    <div className="tag-table-content">
                                        <div
                                            className="tag-table"
                                            style={{
                                                backgroundColor:
                                                    transfer.getColorStatus(
                                                        "provider"
                                                    ),
                                            }}
                                        >
                                            <span className="to-upper">
                                                { t(
                                                    `transfer_kpis_status.${transfer.status}`
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="decision">
                                        <ActionTable
                                            deleteAction={() =>
                                                decisionTransfer(false)
                                            }
                                            validateAction={() =>
                                                decisionTransfer()
                                            }
                                            alreadyOpen={true}
                                        />
                                    </div>
                                )
                            ) : transfer.status == 'running' ? (
                                <div className="tag-table-content">
                                        <div
                                            className="tag-table"
                                            style={{
                                                backgroundColor:
                                                    transfer.getColorStatus(
                                                        "provider"
                                                    ),
                                            }}
                                        >
                                            <span className="to-upper">
                                                {transfer.steps  && transfer.steps.length ? t(
                                                    `transfer_kpis_status.${transfer.status}_${transfer.steps[transfer.steps.length - 1].status}`
                                                ) : t(`transfer_kpis_status.${transfer.status}`) }
                                            </span>
                                        </div>
                                    </div>
                            ) : (
                                <div className="tag-table-content">
                                    <div
                                        className="tag-table"
                                        style={{
                                            backgroundColor:
                                                transfer.getColorStatus("provider"),
                                        }}
                                    >
                                        <span className="to-upper">
                                            {t("status." + transfer.status)}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </BodyTableCell>
                </CheckPermissions>
                <CheckPermissions permissions={[Roles.ADMIN]}>
                        <BodyTableCell align="center" className="tag-status">
                            <div
                                className={`tag-table-section`}
                                style={{ position: "relative" }}
                            >
                                {transfer.status === "to_assign" ? (
                                    <div className="tag-table-content">
                                        <div
                                            className="to_assign_tag"
                                            style={{
                                                backgroundColor:
                                                    transfer.getColorStatus(
                                                        "admin"
                                                    ),
                                            }}
                                        >
                                            <Link
                                                to={`/app/transfer/${transfer.id}/providers`}
                                                className="tag-table"
                                                onMouseEnter={() =>
                                                    setShowNoProvider(true)
                                                }
                                                onMouseLeave={() =>
                                                    setShowNoProvider(false)
                                                }
                                            >
                                                <span className="to-upper">
                                                    {t(
                                                        "provider_status.pending"
                                                    )}
                                                </span>
                                                {showNoProvider && (
                                                    <NoProviderAssigned
                                                        isTender={transfer.isTender()}
                                                    />
                                                )}
                                            </Link>
                                        </div>
                                    </div>
                                ) : transfer.status == 'running' ? (
                                    <div className="tag-table-content">
                                            <div
                                                className="tag-table"
                                                style={{
                                                    backgroundColor:
                                                        transfer.getColorStatus(
                                                            "provider"
                                                        ),
                                                }}
                                            >
                                                <span className="to-upper">
                                                    {transfer.steps  && transfer.steps.length ? t(
                                                        `transfer_kpis_status.${transfer.status}_${transfer.steps[transfer.steps.length - 1].status}`
                                                    ) : t(`transfer_kpis_status.${transfer.status}`) }
                                                </span>
                                            </div>
                                        </div>
                                ) :              
                                (
                                    <div className="tag-table-content">
                                        <div
                                            className="tag-table"
                                            style={{
                                                backgroundColor:
                                                    transfer.getColorStatus(
                                                        "admin"
                                                    ),
                                            }}
                                        >
                                            <Link
                                                to={`/app/transfer/${transfer.id}/providers`}
                                                className="tag-table"
                                            >
                                                <span className="to-upper">
                                                    {t(
                                                        "admin_status." +
                                                            transfer.status
                                                    )}
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </BodyTableCell>
                    </CheckPermissions>
                    <CheckPermissions permissions={[Roles.PROVIDER]}>
                        <BodyTableCell align="center" className="tag-status">
                            <div
                                className="tag-table-section"
                                onMouseEnter={() => setShowDecision(true)}
                                onMouseLeave={() => setShowDecision(false)}
                            >
                                {transfer.status === "pending" &&
                                !transfer.provider_id ? (
                                    !showDecision ? (
                                        <div className="tag-table-content">
                                            <div
                                                className="tag-table"
                                                style={{
                                                    backgroundColor:
                                                        transfer.getColorStatus(
                                                            "provider"
                                                        ),
                                                }}
                                            >
                                                <span className="to-upper">
                                                    {t(
                                                        "transfer_kpis_status.requested_price"
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="decision">
                                            <ActionTable
                                                deleteAction={() =>
                                                    decisionTransfer(false)
                                                }
                                                validateAction={() =>
                                                    decisionTransfer()
                                                }
                                                alreadyOpen={true}
                                            />
                                        </div>
                                    )
                                ) : transfer.status === "accepted" &&
                                  assignment ? (
                                    <div className="tag-table-content">
                                        <div
                                            className="tag-table"
                                            style={{
                                                backgroundColor:
                                                    transfer.getColorStatus(
                                                        "provider"
                                                    ),
                                            }}
                                        >
                                            <span className="to-upper">
                                                {t(
                                                    `transfer_kpis_status.${transfer.status}`
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                ) : transfer.status == 'running' ? (
                                    <div className="tag-table-content">
                                            <div
                                                className="tag-table"
                                                style={{
                                                    backgroundColor:
                                                        transfer.getColorStatus(
                                                            "provider"
                                                        ),
                                                }}
                                            >
                                                <span className="to-upper">
                                                    {transfer.steps  && transfer.steps.length ? t(
                                                        `transfer_kpis_status.${transfer.status}_${transfer.steps[transfer.steps.length - 1].status}`
                                                    ) : t(`transfer_kpis_status.${transfer.status}`) }
                                                </span>
                                            </div>
                                        </div>
                                ) :
                                (
                                    <div className="tag-table-content">
                                        <div
                                            className="tag-table"
                                            style={{
                                                backgroundColor:
                                                    transfer.status !==
                                                    "pending"
                                                        ? transfer.getColorStatus(
                                                              "provider"
                                                          )
                                                        : "rgb(0 193 245 / 66%)",
                                            }}
                                        >
                                            <span className="to-upper">
                                                {transfer.status === "pending"
                                                    ? t("status.to_go")
                                                    : t(
                                                          "status." +
                                                              transfer.status
                                                      )}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </BodyTableCell>
                    </CheckPermissions>

                    <BodyTableCell align="center">
                        {`${transfer.updated_at.toLocaleDateString()} ${t(
                            "at"
                        )}  ${transfer.updated_at?.toLocaleTimeString()}`}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {transfer.getCreated() !== transfer.getUpdated() && (
                            <span
                                style={{ cursor: "pointer" }}
                                onClick={() => setShowHistory(true)}
                            >
                                <ReactSVG src="/images/app_icons/clock-circular-outline.svg" />
                            </span>
                        )}
                    </BodyTableCell>
                    <BodyTableCell align="center" className="note_cell">
                        <ReactSVG
                            src="/images/app_icons/chat.svg"
                            onMouseEnter={() => setShowNote(true)}
                            onMouseLeave={() => setShowNote(false)}
                        />
                        {showNote && <TransferNote transfer={transfer} />}
                    </BodyTableCell>

                    <BodyTableCell align="center">
                        {transfer.passengers &&
                        transfer.passengers.length &&
                        !transfer.passengers_prevent_at ? (
                            <WhiteButton
                                onClick={() => preventPassengers()}
                                className="btn-rounded"
                                variant="contained"
                                color="primary"
                            >
                                {t("preventing_passengers")}
                            </WhiteButton>
                        ) : (
                            ""
                        )}

                        {transfer.passengers &&
                        transfer.passengers.length &&
                        transfer.passengers_prevent_at
                            ? formatDateDDMMYYYY(
                                    transfer.passengers_prevent_at
                                )
                            : ""}

                        {transfer.passengers && !transfer.passengers.length
                            ? t("no_passenger")
                            : ""}
                    </BodyTableCell>

                    <BodyTableCell align="right">
                        { (user.isAdmin() || user.isClient()) && !transfer.unChangeable() ? (
                            <>
                                <ActionTable
                                    deleteAction={() => setIsOpen(true)}
                                    updateAction={() =>
                                        history.push(
                                            `/app/transfer/${transfer.id}/edit`
                                        )
                                    }
                                />
                                <ModalDelete
                                    isOpen={isOpen}
                                    closeDialog={() => setIsOpen(false)}
                                    itemType="transfer"
                                    itemCode={transfer.id.toString()}
                                    itemName={
                                        transfer.departure_location +
                                        " -> " +
                                        transfer.arrival_location
                                    }
                                    handleDelete={() => deleteClient()}
                                />
                            </>
                        ) : (
                            user.isProvider() && (
                                <>
                                    {assignment && (
                                        <ActionTable
                                            deleteAction={() =>
                                                setShowUnassign(true)
                                            }
                                            updateAction={() =>
                                                history.push(
                                                    `/app/transfer/${transfer.id}/amount/${assignment.id}`
                                                )
                                            }
                                        />
                                    )}
                                    {user.provider && (
                                        <ModalUnassignment
                                            isOpen={showUnasign}
                                            transfer={transfer}
                                            closeDialog={() =>
                                                setShowUnassign(false)
                                            }
                                            providerId={
                                                user.provider &&
                                                user.provider.id
                                            }
                                            onRefresh={() => {
                                                onDeleteSuccess();
                                            }}
                                        />
                                    )}
                                </>
                            )
                        )}
                    </BodyTableCell>
                    <ModalHistory
                        isOpen={showHistory}
                        transfer={transfer}
                        closeDialog={() => setShowHistory(false)}
                    />
                

            </BodyTableRow>
        )
    }

    const renderItemClient = () => {
        return (
            <BodyTableRow>
                <BodyTableCell align="center">
                    <div
                        className="link-table"
                        onClick={() =>
                            history.push(`/app/transfer/${transfer.id}/edit`)
                        }
                    >
                        {transfer.code}
                    </div>
                    <div
                        className="link-table"
                        onClick={() =>
                            history.push(
                                `/app/folder/${transfer.folder?.id}/edit`
                            )
                        }
                    >
                        {transfer.folder?.code}
                    </div>
                </BodyTableCell>
                <BodyTableCell align="center" className="client_cell">
                    <ReactSVG
                        src="/images/app_icons/user.svg"
                        className="svg_client"
                        onMouseEnter={() => setShowClient(true)}
                        onMouseLeave={() => setShowClient(false)}
                    />
                    {showClient && transfer?.folder && (
                        <InfoClient transfer={transfer} />
                    )}
                </BodyTableCell>
                {/* <BodyTableCell align="center">
                    {transfer.nb_passenger}
                </BodyTableCell> */}
                <BodyTableCell align="center">
                    {transfer.departure_datetime?.toLocaleDateString()}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {transfer.getHourDeparture()}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {transfer.getHourArrival()}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {transfer.departure_location}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {t(`transfer_type.${transfer.type}`)}
                </BodyTableCell>
                {/* <BodyTableCell align="center">{transfer.infos}</BodyTableCell> */}
                <BodyTableCell align="center">
                    {getDriversName(transfer)}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {getDriversPhone(transfer)}
                </BodyTableCell>
                <BodyTableCell align="center">
                    {transfer.arrival_location}
                </BodyTableCell>

                <BodyTableCell align="center">
                    <div
                        className="tag-table-section"
                        onMouseEnter={() => setShowDecision(true)}
                        onMouseLeave={() => setShowDecision(false)}
                    >
                        {transfer.status === "pending" ? (
                            !showDecision ? (
                                <div className="tag-table-content">
                                    <div
                                        className="tag-table"
                                        style={{
                                            backgroundColor:
                                                transfer.getColorStatus(
                                                    "provider"
                                                ),
                                        }}
                                    >
                                        <span className="to-upper">
                                            {t(
                                                `transfer_kpis_status.${transfer.status}`
                                            )}
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                <div className="decision">
                                    <ActionTable
                                        deleteAction={() =>
                                            decisionTransfer(false)
                                        }
                                        validateAction={() =>
                                            decisionTransfer()
                                        }
                                        alreadyOpen={true}
                                    />
                                </div>
                            )
                        ) : (
                            <div className="tag-table-content">
                                <div
                                    className="tag-table"
                                    style={{
                                        backgroundColor:
                                            transfer.getColorStatus("provider"),
                                    }}
                                >
                                    <span className="to-upper">
                                        {t("status." + transfer.status)}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </BodyTableCell>
                <BodyTableCell align="center">
                    {transfer.getCreated() !== transfer.getUpdated() && (
                        <span
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowHistory(true)}
                        >
                            <ReactSVG src="/images/app_icons/clock-circular-outline.svg" />
                        </span>
                    )}
                </BodyTableCell>
                <BodyTableCell align="right">
                    {!transfer.isCancelled() ? (
                        <>
                            <ActionTable
                                {...(canDelete()
                                    ? { deleteAction: () => setIsOpen(true) }
                                    : {})}
                                updateAction={() =>
                                    history.push(
                                        `/app/transfer/${transfer.id}/edit`
                                    )
                                }
                            />
                            <ModalDelete
                                isOpen={isOpen}
                                closeDialog={() => setIsOpen(false)}
                                itemType="transfer"
                                itemCode={transfer.id.toString()}
                                itemName={
                                    transfer.departure_location +
                                    " -> " +
                                    transfer.arrival_location
                                }
                                handleDelete={() => deleteClient()}
                            />
                        </>
                    ) : (
                        ""
                    )}
                </BodyTableCell>
                <ModalHistory
                    isOpen={showHistory}
                    transfer={transfer}
                    closeDialog={() => setShowHistory(false)}
                />
            </BodyTableRow>
        );
    };

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: "#f5f5f9",
            color: "rgba(0, 0, 0, 0.87)",
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: "1px solid #dadde9",
        },
    }))(Tooltip);

    const renderItemAdminPresta = () => {
        return (
            <>
                <BodyTableRow>
                    <BodyTableCell>
                        {/* TODO - Add folder image link into ImagePreview */}
                        <div className="upload-image-table">
                            <ImagePreview
                                size="litle"
                                existingImageUrl={null}
                                onUpdateImage={() => null}
                                disabledForm
                            />
                        </div>
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {user.isProvider() && (
                            <>
                                {!transfer.is_confirmed && (
                                    <HtmlTooltip
                                        title={
                                            <div
                                                className="tooltip"
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <strong
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    Le transfert a été modifié
                                                </strong>
                                                <span
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    Veuillez valider votre
                                                    demande
                                                </span>
                                            </div>
                                        }
                                    >
                                        <div
                                            onClick={() =>
                                                setShowModalTransferConfirmation(
                                                    true
                                                )
                                            }
                                        >
                                            <ReactSVG
                                                beforeInjection={(svg) => {
                                                    svg.setAttribute(
                                                        "style",
                                                        "width: 25px"
                                                    );
                                                    svg.setAttribute(
                                                        "style",
                                                        "height: 25px"
                                                    );
                                                }}
                                                src="/images/app_icons/exclamation-mark.svg"
                                            />
                                        </div>
                                    </HtmlTooltip>
                                )}

                                <div>{transfer.code}</div>
                                <div>{transfer.folder?.code}</div>
                            </>
                        )}
                        {user.isAdmin() && (
                            <>
                                {!transfer.is_confirmed && (
                                    <HtmlTooltip
                                        title={
                                            <div
                                                className="tooltip"
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <strong
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    Le transfert a été modifié
                                                </strong>
                                                <span
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    En attente de la validation
                                                    du prestataire
                                                </span>
                                            </div>
                                        }
                                    >
                                        <ReactSVG
                                            beforeInjection={(svg) => {
                                                svg.setAttribute(
                                                    "style",
                                                    "width: 25px"
                                                );
                                                svg.setAttribute(
                                                    "style",
                                                    "height: 25px"
                                                );
                                            }}
                                            src="/images/app_icons/exclamation-mark.svg"
                                        />
                                    </HtmlTooltip>
                                )}

                                <div
                                    className="link-table"
                                    onClick={() =>
                                        history.push(
                                            `/app/transfer/${transfer.id}/edit`
                                        )
                                    }
                                >
                                    {transfer.code}
                                </div>
                                <div
                                    className="link-table"
                                    onClick={() =>
                                        history.push(
                                            `/app/folder/${transfer.folder?.id}/edit`
                                        )
                                    }
                                >
                                    {transfer.folder?.code}
                                </div>
                            </>
                        )}
                    </BodyTableCell>
                    <BodyTableCell align="center" className="client_cell">
                        {transfer.canShowClientOnTransfer() ||
                        user.isAdmin() ? (
                            <ReactSVG
                                src="/images/app_icons/user.svg"
                                className="svg_client"
                                onMouseEnter={() => setShowClient(true)}
                                onMouseLeave={() => setShowClient(false)}
                            />
                        ) : (
                            <></>
                        )}
                        {showClient && transfer?.folder && (
                            <InfoClient transfer={transfer} />
                        )}
                    </BodyTableCell>
                    {/* <BodyTableCell align="center">
                        {transfer.passengers?.map(
                            (passenger: Passenger, index: number) => (
                                <div
                                    key={`passenger-name-${passenger.id}-table-${index}`}
                                >
                                    <span>{passenger.getFullName()}</span>
                                    <br />
                                </div>
                            )
                        )}
                    </BodyTableCell> */}
                    {/* <BodyTableCell align="center">
                        {transfer.passengers?.map(
                            (passenger: Passenger, index: number) => (
                                <div
                                    key={`passenger-phone-${passenger.id}-table-${index}`}
                                >
                                    <span>+{passenger.phone}</span>
                                    <br />
                                </div>
                            )
                        )}
                    </BodyTableCell> */}
                    {/* <BodyTableCell align="center">
                        {transfer.passengers?.map(
                            (passenger: Passenger, index: number) => (
                                <div
                                    key={`passenger-mail-${passenger.id}-table-${index}`}
                                >
                                    <a
                                        className="link-table"
                                        href={`mailto:${passenger.email}`}
                                    >
                                        {passenger.email}
                                    </a>
                                    <br />
                                </div>
                            )
                        )}
                    </BodyTableCell> */}
                    <BodyTableCell align="center">
                        {transfer.departure_datetime?.toLocaleDateString()}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {transfer.getHourDeparture()}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {transfer.getHourArrival()}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {transfer.departure_location}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {t(`transfer_type.${transfer.type}`)}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {transfer.infos}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {getDriversName(transfer)}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {getDriversPhone(transfer)}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {transfer.arrival_location}
                    </BodyTableCell>
                    <CheckPermissions permissions={[Roles.ADMIN]}>
                        <BodyTableCell align="center" className="tag-status">
                            <div
                                className={`tag-table-section`}
                                style={{ position: "relative" }}
                            >
                                {transfer.status === "to_assign" ? (
                                    <div className="tag-table-content">
                                        <div
                                            className="to_assign_tag"
                                            style={{
                                                backgroundColor:
                                                    transfer.getColorStatus(
                                                        "admin"
                                                    ),
                                            }}
                                        >
                                            <Link
                                                to={`/app/transfer/${transfer.id}/providers`}
                                                className="tag-table"
                                                onMouseEnter={() =>
                                                    setShowNoProvider(true)
                                                }
                                                onMouseLeave={() =>
                                                    setShowNoProvider(false)
                                                }
                                            >
                                                <span className="to-upper">
                                                    {t(
                                                        "provider_status.pending"
                                                    )}
                                                </span>
                                                {showNoProvider && (
                                                    <NoProviderAssigned
                                                        isTender={transfer.isTender()}
                                                    />
                                                )}
                                            </Link>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="tag-table-content">
                                        <div
                                            className="tag-table"
                                            style={{
                                                backgroundColor:
                                                    transfer.getColorStatus(
                                                        "admin"
                                                    ),
                                            }}
                                        >
                                            <Link
                                                to={`/app/transfer/${transfer.id}/providers`}
                                                className="tag-table"
                                            >
                                                <span className="to-upper">
                                                     { transfer.steps && transfer.steps.length ?  
                                                     t(`admin_status.${transfer.status}_${transfer.steps[transfer.steps.length - 1].status}`) : t("admin_status." +transfer.status)}
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </BodyTableCell>
                    </CheckPermissions>
                    <CheckPermissions permissions={[Roles.PROVIDER]}>
                        <BodyTableCell align="center" className="tag-status">
                            <div
                                className="tag-table-section"
                                onMouseEnter={() => setShowDecision(true)}
                                onMouseLeave={() => setShowDecision(false)}
                            >
                                {transfer.status === "pending" &&
                                !transfer.provider_id ? (
                                    !showDecision ? (
                                        <div className="tag-table-content">
                                            <div
                                                className="tag-table"
                                                style={{
                                                    backgroundColor:
                                                        transfer.getColorStatus(
                                                            "provider"
                                                        ),
                                                }}
                                            >
                                                <span className="to-upper">
                                                    {t(
                                                        "transfer_kpis_status.requested_price"
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="decision">
                                            <ActionTable
                                                deleteAction={() =>
                                                    decisionTransfer(false)
                                                }
                                                validateAction={() =>
                                                    decisionTransfer()
                                                }
                                                alreadyOpen={true}
                                            />
                                        </div>
                                    )
                                ) : transfer.status === "accepted" &&
                                  assignment ? (
                                    <div className="tag-table-content">
                                        <div
                                            className="tag-table"
                                            style={{
                                                backgroundColor:
                                                    transfer.getColorStatus(
                                                        "provider"
                                                    ),
                                            }}
                                        >
                                            <span className="to-upper">
                                                {t(
                                                    `transfer_kpis_status.${transfer.status}`
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="tag-table-content">
                                        <div
                                            className="tag-table"
                                            style={{
                                                backgroundColor:
                                                    transfer.status !==
                                                    "pending"
                                                        ? transfer.getColorStatus(
                                                              "provider"
                                                          )
                                                        : "rgb(0 193 245 / 66%)",
                                            }}
                                        >
                                            <span className="to-upper">
                                                
                                                {transfer.status === "pending"
                                                    ? t("status.to_go")
                                                    : t(
                                                          "status." +
                                                              transfer.status
                                                      )}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </BodyTableCell>
                    </CheckPermissions>
                    <BodyTableCell align="center">
                        {`${transfer.updated_at.toLocaleDateString()} ${t(
                            "at"
                        )}  ${transfer.updated_at?.toLocaleTimeString()}`}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {transfer.getCreated() !== transfer.getUpdated() && (
                            <span
                                style={{ cursor: "pointer" }}
                                onClick={() => setShowHistory(true)}
                            >
                                <ReactSVG src="/images/app_icons/clock-circular-outline.svg" />
                            </span>
                        )}
                    </BodyTableCell>
                    <BodyTableCell align="center" className="note_cell">
                        <ReactSVG
                            src="/images/app_icons/chat.svg"
                            onMouseEnter={() => setShowNote(true)}
                            onMouseLeave={() => setShowNote(false)}
                        />
                        {showNote && <TransferNote transfer={transfer} />}
                    </BodyTableCell>
                    {user.isAdmin() && (
                        <BodyTableCell align="center">
                            {transfer.passengers &&
                            transfer.passengers.length &&
                            !transfer.passengers_prevent_at ? (
                                <WhiteButton
                                    onClick={() => preventPassengers()}
                                    className="btn-rounded"
                                    variant="contained"
                                    color="primary"
                                >
                                    {t("preventing_passengers")}
                                </WhiteButton>
                            ) : (
                                ""
                            )}

                            {transfer.passengers &&
                            transfer.passengers.length &&
                            transfer.passengers_prevent_at
                                ? formatDateDDMMYYYY(
                                      transfer.passengers_prevent_at
                                  )
                                : ""}

                            {transfer.passengers && !transfer.passengers.length
                                ? t("no_passenger")
                                : ""}
                        </BodyTableCell>
                    )}
                    <BodyTableCell align="right">
                        {user.isAdmin() && !transfer.unChangeable() ? (
                            <>
                                <ActionTable
                                    deleteAction={() => setIsOpen(true)}
                                    updateAction={() =>
                                        history.push(
                                            `/app/transfer/${transfer.id}/edit`
                                        )
                                    }
                                />
                                <ModalDelete
                                    isOpen={isOpen}
                                    closeDialog={() => setIsOpen(false)}
                                    itemType="transfer"
                                    itemCode={transfer.id.toString()}
                                    itemName={
                                        transfer.departure_location +
                                        " -> " +
                                        transfer.arrival_location
                                    }
                                    handleDelete={() => deleteClient()}
                                />
                            </>
                        ) : (
                            user.isProvider() && (
                                <>
                                    {assignment && (
                                        <ActionTable
                                            deleteAction={() =>
                                                setShowUnassign(true)
                                            }
                                            updateAction={() =>
                                                history.push(
                                                    `/app/transfer/${transfer.id}/amount/${assignment.id}`
                                                )
                                            }
                                        />
                                    )}
                                    {user.provider && (
                                        <ModalUnassignment
                                            isOpen={showUnasign}
                                            transfer={transfer}
                                            closeDialog={() =>
                                                setShowUnassign(false)
                                            }
                                            providerId={
                                                user.provider &&
                                                user.provider.id
                                            }
                                            onRefresh={() => {
                                                onDeleteSuccess();
                                            }}
                                        />
                                    )}
                                </>
                            )
                        )}
                    </BodyTableCell>
                    <ModalHistory
                        isOpen={showHistory}
                        transfer={transfer}
                        closeDialog={() => setShowHistory(false)}
                    />
                </BodyTableRow>
                <Modal
                    label="Validation du transfert"
                    isOpen={showModalTransferConfirmation}
                    closeModal={() => setShowModalTransferConfirmation(false)}
                >
                    <RecapFolder
                        transfer={transfer}
                        control={control}
                        errors={[]}
                        vehicleType={currentVehiculeType}
                    />

                    <div className="actions">
                        <Button
                            onClick={() => cancelUpdatedTransfer()}
                            className="btn-form btn-icon mr-2 submit-input"
                            variant="contained"
                            style={{ backgroundColor: Colors.red }}
                            size="large"
                            type="button"
                        >
                            {t("cancel")}{" "}
                            <ReactSVG src="/images/app_icons/icon-delete.svg" />
                        </Button>

                        <Button
                            onClick={() => acceptUpdatedTransfer()}
                            className="btn-form btn-icon mr-2 submit-input"
                            variant="contained"
                            color="primary"
                            size="large"
                            type="button"
                        >
                            {t("validate")} <Icon>{"done"}</Icon>
                        </Button>
                    </div>
                </Modal>
            </>
        );
    };

    return renderItem();
    /*user.role === Roles.CLIENT
        ? renderItemClient()
        : renderItemAdminPresta();*/
       
};

export default ItemTransfer;
