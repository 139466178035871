import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Transfer } from "../../../models/transfer";
import "./FormTransferAmount.scss";
import { TransferResponseOption } from "../../../models/transfer_response";
import TransferService from "../../../services/transfer-service";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import { ErrorForm } from "../../../components/ErrorForm";
import { VehicleType } from "../../../models/vehicle-type";
import VehicleTypeService from "../../../services/vehicle-type-service";
import { Button, CircularProgress, Icon } from "@material-ui/core";
import RecapFolder from "./RecapFolder/RecapFolder";
import FormProposition from "./FormProposition/FormProposition";
import { Assignment } from "../../../models/assignment";
import FormPropositionTender from "./FormPropositionTender/FormPropositionTender";
import ModalConfirmUpdateTender from "./modal-confirm-update-tender/ModalConfirmUpdateTender";
import { Driver } from "../../../models/driver";
import { VehicleOption } from "../../../models/vehicle-option";
import { Vehicle } from "../../../models/vehicle";

interface IFormTransferAmount {
    transfer: Transfer;
    drivers: any;
    assignment?: Assignment;
    vehicles: Vehicle[];
}

const FormTransferAmount = ({
    transfer,
    drivers,
    assignment,
    vehicles,
}: IFormTransferAmount) => {
    const history = useHistory();
    const {
        handleSubmit,
        errors,
        control,
        setValue,
        register,
        getValues,
        setError,
    } = useForm<any>();
    const { t } = useTranslation();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [accept, setAccept] = useState(true);

    const [driverChoiceTender, setDriverChoiceTender] =
        useState<Driver | null>();

    const [responseOptions, setResponseOptions] = useState<
        TransferResponseOption[]
    >(
        transfer?.options &&
            assignment?.response?.options &&
            assignment?.response?.options?.length > 0
            ? assignment.response.options.map(
                  (item: TransferResponseOption) => {
                      const opTrans = transfer.options?.filter(
                          (o: VehicleOption) => o.id === item.vehicle_option_id
                      );
                      return new TransferResponseOption({
                          id: opTrans && opTrans[0] && opTrans[0].id,
                          label: opTrans && opTrans[0] && opTrans[0].label,
                          price: item.price,
                          tva: item.tva,
                      });
                  }
              )
            : []
    );

    // console.log(transfer.options, assignment && assignment.response);
    const [disabledForm, setDisabledForm] = useState(false);
    const [currentVehiculeType, setCurrentVehiculeType] =
        useState<VehicleType>();

    const onSubmit = async (data: any, e: any) => {
        try {
            setIsLoading(true);
            const service = new TransferService();
            setDisabledForm(true);
            const resp = {
                response: {
                    driver_id: data.driver_id,
                    sms_detail: data.sms_detail,
                    price: data.ht_price,
                    tva: data.tva,
                    vehicle_id: data.vehicle_id,
                    options: responseOptions.map(
                        (item: TransferResponseOption) => {
                            return {
                                id: item.id,
                                label: item.label,
                                price: item.price,
                                tva: item.tva,
                            };
                        }
                    ),
                },
            };
            await service.accept(transfer.id, resp);
            toastr.success(t("success"), t("success_propose_service"));
            history.push("/app/transfers");
            setDisabledForm(false);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            e.response.status === 404
                ? toastr.error(t("error"), t("driver_required"))
                : setErrorApi(new ErrorFormData(e.response.data));
        }
    };

    const handleRefuse = async () => {
        try {
            if (transfer.tender_status === "validated" && !isOpen) {
                setIsOpen(true);
                setAccept(false);
                return;
            }
            setDisabledForm(true);
            setIsLoading(true);
            const service = new TransferService();
            assignment || transfer.tender_status === "validated"
                ? await service.updateTender(transfer.id, {
                      status: "refused",
                  })
                : await service.acceptTender(transfer.id, {
                      status: "refused",
                  });
            toastr.success(t("success"), t("success_refuse_tender"));
            history.push("/app/tenders");
            setDisabledForm(false);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setErrorApi(new ErrorFormData(e.response.data));
        }
    };

    const submitFromModal = async () => {
        try {
            setIsLoading(true);

            const service = new TransferService();
            const resp = {
                status: "accepted",
                driver_id: driverChoiceTender?.id,
                response: {
                    price: getValues("price"),
                    tva: 20.0,
                },
            };
            await service.updateTender(transfer.id, resp);
            toastr.success(t("success"), t("success_propose_service"));
            history.push("/app/tenders");

            setDisabledForm(false);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setErrorApi(new ErrorFormData(e.response.data));
        }
    };

    const onSubmitTender = async (data: any) => {
        try {
            const service = new TransferService();
            if (
                transfer.min_estimate &&
                Number(data.price) < Number(transfer.min_estimate)
            ) {
                setError("price", {
                    message: "price_sould_match_estimate",
                });
                setDisabledForm(false);
                return;
            }
            if (transfer.tender_status === "validated" && !isOpen) {
                setIsOpen(true);
                setAccept(true);
                return;
            }
            setDisabledForm(true);
            setIsLoading(true);
            const resp = {
                status: "accepted",
                driver_id: driverChoiceTender?.id,
                response: {
                    price: data.price,
                    tva: 20.0,
                },
            };

            assignment
                ? await service.updateTender(transfer.id, resp)
                : await service.acceptTender(transfer.id, resp);

            toastr.success(t("success"), t("success_propose_service"));
            history.push("/app/tenders");

            setDisabledForm(false);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setErrorApi(new ErrorFormData(e.response.data));
        }
    };

    const loadVehiclesType = useCallback(async () => {
        const vehicleTypeService = new VehicleTypeService();
        const vehiculesTypes = await vehicleTypeService.all();
        setCurrentVehiculeType(
            vehiculesTypes.find(
                (vehicleType: VehicleType) =>
                    vehicleType.id === transfer.vehicle_type_id
            )
        );
    }, [transfer]);

    useEffect(() => {
        register("sms_detail");
        register("driver");
        register("driver_id");
        assignment?.response?.driver &&
            setValue("driver_id", assignment?.response?.driver.id);
        loadVehiclesType();
    }, [register, transfer, setValue, assignment, loadVehiclesType]);

    return (
        <div className="form-section-amount-transfer row">
            <form
                className="form-react-amount-transfer col-12 "
                onSubmit={
                    transfer.is_tender
                        ? handleSubmit(onSubmitTender)
                        : handleSubmit(onSubmit)
                }
            >
                <RecapFolder
                    transfer={transfer}
                    control={control}
                    errors={errors}
                    vehicleType={currentVehiculeType}
                />
                {!transfer.is_tender ? (
                    <FormProposition
                        transfer={transfer}
                        setResponseOptions={(e: any) => setResponseOptions(e)}
                        responseOptions={responseOptions}
                        drivers={drivers}
                        setValue={(e: any, e2: any) => setValue(e, e2)}
                        getValues={(e: any) => getValues(e)}
                        control={control}
                        errors={errors}
                        disabledForm={disabledForm}
                        assignment={assignment}
                        vehicles={vehicles}
                    />
                ) : (
                    <FormPropositionTender
                        transfer={transfer}
                        drivers={drivers}
                        setValue={(e: any, e2: any) => setValue(e, e2)}
                        control={control}
                        errors={errors}
                        disabledForm={disabledForm}
                        assignment={assignment}
                        driverChoice={driverChoiceTender}
                        setDriverChoice={setDriverChoiceTender}
                        vehicles={vehicles}
                    />
                )}

                <div className="buttons-content">
                    {!isLoading ? (
                        <>
                            {transfer.isTender() ? (
                                transfer.tender_status !== "refused" && (
                                    <Button
                                        className="btn-form btn-icon"
                                        variant="contained"
                                        color="secondary"
                                        size="large"
                                        onClick={handleRefuse}
                                    >
                                        <span>{t("decline")}</span>{" "}
                                        <Icon>close</Icon>
                                    </Button>
                                )
                            ) : (
                                <Button
                                    className="btn-form btn-icon"
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    onClick={() =>
                                        history.push("/app/transfers")
                                    }
                                >
                                    <span>{t("decline")}</span>{" "}
                                    <Icon>close</Icon>
                                </Button>
                            )}

                            <Button
                                className="btn-form btn-icon"
                                variant="contained"
                                color="primary"
                                size="large"
                                type="submit"
                            >
                                <span>{t("send_proposition")}</span>
                                <Icon>check</Icon>
                            </Button>
                            <ModalConfirmUpdateTender
                                isOpen={isOpen}
                                closeDialog={() => setIsOpen(false)}
                                tender={transfer}
                                handleAccept={() => submitFromModal()}
                                handleRefuse={() => handleRefuse()}
                                loading={isLoading}
                                accept={accept}
                            />
                        </>
                    ) : (
                        <CircularProgress />
                    )}
                </div>
            </form>
            <ErrorForm errorApi={errorApi} />
        </div>
    );
};

export default FormTransferAmount;
