import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import Modal from '../../../components/modal/Modal';
import TransferService from '../../../services/transfer-service';
import './ModalAddTransfers.scss';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';

interface IProps{
    isOpen: boolean;
    closeModal: () => void;
    importSuccess: () => void;
    folderId?: number | null;
}
export default function ModalAddTransfers({isOpen, closeModal, importSuccess, folderId}: IProps){
    const { t } = useTranslation();
    const history = useHistory();
    const { register, setValue, getValues } = useForm();

    const [uploadForm, setUploadForm] = useState(false);
    const [errorDetail, setErrorDetail] = useState<string | null>();

    const [fileInZone, setFileInZone] = useState(false);
    const [loadingImport, setLoadingImport] = useState(false);

    const manualAdd = () => {
        history.push(`/app/folder/${folderId}/transfer/add`);
    }

    const onImport = async () => {
        if (!folderId) {
            toastr.error(t('error'), t('error_import_select_folder'));
            setLoadingImport(false);
            return;
        }
        const data = getValues();
        const dataService = new TransferService();
       
        try {
            let report = await dataService.import(folderId, data);
            toastr.success(t('success'), t('success_import') + ' ' + (
                    (report.nb_lines === 1) 
                        ? t('import_report_one')
                        : t('import_report_more', {imported: report.nb_lines})
                )
            );
            setUploadForm(false);
            setLoadingImport(false);
            importSuccess();
        } catch (error) {
            if (error.response) {
                if(error.response.data.errors && error.response.data.errors.details){
                    setErrorDetail(error.response.data.errors.details);
                }
            }
            setLoadingImport(false);
        }
    } 

    const onDrop = (event: any) => {
        setErrorDetail(null);
        event.preventDefault();
        setFileInZone(false);
        const file = event.dataTransfer.items ? event.dataTransfer.items[0].getAsFile() : event.dataTransfer.files[0];
        const filenameSplitted = file.name.split('.');
        const extension = filenameSplitted[filenameSplitted.length - 1];
        if(extension === 'xlsx'){
            setLoadingImport(true);
            let reader = new FileReader();
            reader.readAsDataURL(file); 
            reader.onload = (_event) => { 
                setValue('file_name', file.name);
                setValue('file_binary', reader.result);
                onImport();
            }
        } else {
            toastr.error(t('error'), t('excel_bad_format'));
        }
    }

    const downloadGabarit = () => {
        window.open('/download/Gabarit-Transferts.xlsx', '_blank');
    }

    const cleanModalAndCloseModal = () => {
        setErrorDetail(null);
        setUploadForm(false);
        closeModal();
    }

    return (
        <Modal label={t('add_yours_transfers')} isOpen={isOpen} closeModal={() => cleanModalAndCloseModal()}>
            <>
                {!uploadForm ? 
                    <div className="page-modal">
                        <div className="content-box">
                            <span>{t('excel_import')}</span>
                            <p dangerouslySetInnerHTML={{
                                __html: t('import_desc').split('\n').join('<br />')
                            }} />
                            <Button className="btn-form btn-icon" variant="contained" color="primary" size="large"
                                disabled={uploadForm}
                                onClick={() => setUploadForm(true)}
                            >
                                <span>{t('import')}</span> 
                                <GetAppIcon />
                            </Button>
                        </div>

                        <div className="vertical-separator"></div>

                        <div className="content-box">
                            <span>{t('manual_add')}</span>
                            <p dangerouslySetInnerHTML={{
                                __html: t('manual_desc').split('\n').join('<br />')
                            }} />
                            <Button className="btn-form btn-icon" variant="contained" color="primary" size="large"
                                onClick={() => manualAdd()}
                            >
                                <span>{t('add')}</span> 
                                <AddIcon />
                            </Button>
                        </div>
                    </div> : '' 
                }

                {uploadForm ? 
                    <div className="page-modal">
                        <div className="content-box"> 
                            <span>{t('download_template')}</span>
                            <p>{t('transfer_import_warning')}</p>
                            <div className="gabarit">
                                <div className="example-file">
                                    <img src="/images/app_icons/icon-xlsx.png" alt="xlsx" />
                                    <div className="text-gabarit">
                                        <span className="filename">Import.xlxs</span>
                                        <span className="size">5 ko</span>
                                    </div>
                                </div>
                                <div className="download-file" onClick={() => downloadGabarit()}>
                                    <img src="/images/app_icons/icon-download.png" alt="download" />
                                </div>
            
                            </div>
                        </div>
                        <div className="vertical-separator"></div>
                        <div className="content-box">
                            <input type="hidden" name="file_binary" ref={register()} />
                            <input type="hidden" name="file_name" ref={register()} />
                            <span>{t('upload_file')}</span>
                            <div className={fileInZone ? 'container-drag-and-drop drag-enter': 'container-drag-and-drop' } 
                                 draggable="true" 
                                 onDrop={(e) => onDrop(e)}
                                 onDragOver={(e) => e.preventDefault()}
                                 onDragEnter={() => setFileInZone(true)}
                                 onDragLeave={() => setFileInZone(false)}
                            >
                                <img src="/images/app_icons/icon-drag-and-drop.png" alt="download" />
                                {!loadingImport ? <span className="title">Drag & Drop</span> : ''}
                                {fileInZone ? <span className="description">{t('drop_file')}</span> : 
                                            !loadingImport ? <span className="description">{t('insert_file')}</span>
                                            : <span className="description">{t('loading')}</span> }
                            </div>
                            {errorDetail ? <span className="error-import">{errorDetail}</span> : ''}
                        </div>
                    </div> : '' 
                }
            </>
        </Modal>
    )
}