import { ExteriorColor } from "../../../models/exterior-color";
import React from "react";
import { ReactSVG } from "react-svg";
import "./ImgExteriorColor.scss";

interface iExteriorColor {
  exteriorColor?: ExteriorColor | null;
  renderText?: boolean;
}

const ImgExteriorColor = (props: iExteriorColor) => {
  if (props.exteriorColor) {
    const colorStyle = {
      backgroundColor: props.exteriorColor.hex_color,
      width: "40px",
      height: "40px",
      borderRadius: "6px",
      // boxShadow: "2px 2px 6px #808080d9",
      // border: "1px solid black",
    };
    console.log(props.exteriorColor)
    return (
      <div className="vehicle_color_ext">
        {
        // props.exteriorColor.img_url ? (
        //   <>
        //     <ReactSVG src={props.exteriorColor.img_url} />
        //     {props.renderText ? <span>{props.exteriorColor.color}</span> : ""}
        //   </>
        // ) : 
        props.exteriorColor.hex_color ? (
          <>
            <div style={colorStyle}></div>
            {props.renderText ? <span>{props.exteriorColor.color}</span> : ""}
          </>
        ) : (
          <>{props.exteriorColor.color}</>
        )}
      </div>
    );
  }

  return <></>;
};

ImgExteriorColor.defaultProps = {
  renderText: true,
};

export default ImgExteriorColor;
