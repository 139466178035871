import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ImagePreview from "../../../../components/image-preview/ImagePreview";
import BodyTableCell from "../../../../components/table/BodyTableCell";
import BodyTableRow from "../../../../components/table/BodyTableRow";
import { HistoryTransfer } from "../../../../models/history-transfer";
import RestoreIcon from "@material-ui/icons/Restore";
import "./ItemHistoryTransfer.scss";
import { Roles } from "../../../../models/user";
import CheckPermissions from "../../../../utils/CheckPermissions";
import ActionTable from "../../../../components/action-table/ActionTable";

interface IItemHistoryTransfer {
    index: number;
    historyTransfers: HistoryTransfer[];
    historyTransfer: any;
    restore: (id: number) => void;
}

const ItemHistoryTransfer = ({
    index,
    historyTransfers,
    historyTransfer,
    restore,
}: IItemHistoryTransfer) => {
    const { t } = useTranslation();
    const [showDecision, setShowDecision] = useState<boolean>(false);

    const valueHasChanged = (key: string) => {
        if (index !== historyTransfers.length + 1) {
            const lastTransfers: any = historyTransfers[index + 1];
            if (lastTransfers) {
                /*if (key.includes("p.")) {
                    const pkey = key.substring(2);
                    const lastPassenger = lastTransfers.passengers[0];
                    const passenger = historyTransfer.passengers[0];

                    if (pkey === "fullname") {
                        if (
                            `${lastPassenger["firstname"]} ${lastPassenger["lastname"]}` !==
                            `${passenger["firstname"]} ${passenger["lastname"]}`
                        )
                            return true;
                    } else {
                        if (lastPassenger[pkey] !== passenger[pkey]) {
                            return true;
                        }
                    }
                } else {*/
                    if (lastTransfers[key] !== historyTransfer[key]) {
                        return true;
                    }
                //}
            }
            return false;
        }
    };

    return (
        <BodyTableRow>
            <BodyTableCell align="center">
                {`${t("updated_at")} ${historyTransfer.getHourUpdated()}`}
            </BodyTableCell>
            <BodyTableCell align="center">
                <div className="upload-image-table">
                    <ImagePreview
                        size="litle"
                        existingImageUrl={
                            historyTransfer.folder?.client?.img_url
                        }
                        onUpdateImage={() => null}
                        disabledForm
                    />
                </div>
            </BodyTableCell>
            <BodyTableCell
                align="center"
                className={valueHasChanged("type") ? "has_changed" : ""}
            >
                {historyTransfer.type}
            </BodyTableCell>
            <BodyTableCell
                align="center"
                className={valueHasChanged("nb_passenger") ? "has_changed" : ""}
            >
                {historyTransfer.nb_passenger}
            </BodyTableCell>
            <BodyTableCell
                align="center"
                className={valueHasChanged("p.fullname") ? "has_changed" : ""}
            >
                <div>
                    <span>
                        {historyTransfer.passengers.length > 0 &&
                            `${historyTransfer.passengers[0].firstname} ${historyTransfer.passengers[0].lastname}`}
                    </span>
                    <br />
                </div>
            </BodyTableCell>
            <BodyTableCell
                align="center"
                className={valueHasChanged("p.phone") ? "has_changed" : ""}
            >
                <div>
                    <span>
                        {historyTransfer.passengers.length > 0 &&
                            historyTransfer.passengers[0].phone}
                    </span>
                    <br />
                </div>
            </BodyTableCell>
            <BodyTableCell
                align="center"
                className={valueHasChanged("p.email") ? "has_changed" : ""}
            >
                <div>
                    <span>
                        {historyTransfer.passengers.length > 0 &&
                            historyTransfer.passengers[0].email}
                    </span>
                    <br />
                </div>
            </BodyTableCell>
            <BodyTableCell
                align="center"
                className={
                    valueHasChanged("departure_location") ? "has_changed" : ""
                }
            >
                {historyTransfer.departure_location}
            </BodyTableCell>
            <BodyTableCell
                align="center"
                className={
                    valueHasChanged("arrival_location") ? "has_changed" : ""
                }
            >
                {historyTransfer.arrival_location}
            </BodyTableCell>

            <BodyTableCell
                align="center"
                className={`tag-status ${
                    valueHasChanged("status") ? "has_changed" : ""
                }`}
            >
                <div
                    className={`tag-table-section`}
                    style={{ position: "relative" }}
                >
                    {historyTransfer.status === "to_assign" ? (
                        <div className="tag-table-content">
                            <div
                                className="to_assign_tag"
                                style={{
                                    backgroundColor:
                                        historyTransfer.getColorStatus("admin"),
                                }}
                            >
                                <div className="tag-table">
                                    <span className="to-upper">
                                        {t("provider_status.pending")}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="tag-table-content">
                            <div
                                className="tag-table"
                                style={{
                                    backgroundColor:
                                        historyTransfer.getColorStatus("admin"),
                                }}
                            >
                                <div className="tag-table">
                                    <span className="to-upper">
                                        {t(
                                            "admin_status." +
                                                historyTransfer.status
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </BodyTableCell>
            <CheckPermissions permissions={[Roles.ADMIN, Roles.CLIENT]}>
                <BodyTableCell>
                    <div className="">
                        {!showDecision ? (
                            <RestoreIcon
                                className="restore-icon"
                                onClick={() => setShowDecision(true)}
                            />
                        ) : (
                            <ActionTable
                                deleteAction={() => setShowDecision(false)}
                                validateAction={() =>
                                    restore(historyTransfer.id)
                                }
                                alreadyOpen
                            />
                        )}
                    </div>
                </BodyTableCell>
            </CheckPermissions>
        </BodyTableRow>
    );
};

export default ItemHistoryTransfer;
