import React from "react";
import "./InfoClient.scss";
import { useTranslation } from "react-i18next";
import { Client } from "../../../models/client";
import { Transfer } from "../../../models/transfer";

interface IPopupClient {
    transfer: Transfer;
}

const InfoClient = ({ transfer }: IPopupClient) => {
    console.log(transfer)
    const { t } = useTranslation();
    return transfer ? (
        <div className="infos_client_popup">
            <div>
                <div className="grid-4 title">
                    <span></span>
                    <span>{t("company")}</span>
                    <span>{t("form.referent_name")}</span>
                    <span>{t("email")}</span>
                </div>
                <div className="grid-4">
                    <span>{t("client")}</span>
                    <span>{transfer.folder?.client?.company_name}</span>
                    <span>{transfer.folder?.client?.referent_name}</span>
                    <span>{transfer.folder?.client?.email}</span>
                </div>
            </div>

            <span style={{textAlign: 'start'}}>{t("nb_passengers")} : {transfer.nb_passenger}</span>

            <div>
                <div className="grid-4 title">
                    <span>{t("lastname")}</span>
                    <span>{t("firstname")}</span>
                    <span>{t("phone")}</span>
                    <span>{t("email")}</span>
                </div>
                {transfer.passengers?.map((passenger) => {
                    return (
                        <div className="grid-4">
                            <span>{passenger.lastname}</span>
                            <span>{passenger.firstname}</span>
                            <span>{passenger.phone}</span>
                            <span>{passenger.email}</span>
                        </div>
                    )
                })}
            </div>
            {/* <div className="top-info section">
                <div className="info_part">
                    <span className="label">{t("form.client")}</span>
                    <span>{client.company_name}</span>
                </div>
                <div className="info_part">
                    <span className="label">{t("form.civility")}</span>
                    <span>{client.user?.gender}</span>
                </div>
            </div>
            <div className="bottom-info section">
                <div className="info_part">
                    <span className="label">{t("firstname")}</span>
                    <span>{client.user?.firstname}</span>
                </div>
                <div className="info_part last">
                    <span className="label">{t("name")}</span>
                    <span>{client.user?.lastname}</span>
                </div>
            </div> */}
        </div>
    ) : (
        <div className="no_infos_client_popup">{t("client_not_found")}</div>
    );
};

export default InfoClient;
