import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./ModalHistory.scss";
import {
    Button,
    CircularProgress,
    Icon,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { toastr } from "react-redux-toastr";
import Modal from "../../../../components/modal/Modal";
import TransferService from "../../../../services/transfer-service";
import { HistoryTransfer } from "../../../../models/history-transfer";
import HeadTableCell from "../../../../components/table/HeadTableCell";
import ItemHistoryTransfer from "./ItemHistoryTransfer";
import BodyTableRow from "../../../../components/table/BodyTableRow";
import BodyTableCell from "../../../../components/table/BodyTableCell";
import ImagePreview from "../../../../components/image-preview/ImagePreview";
import CheckPermissions from "../../../../utils/CheckPermissions";
import { Roles } from "../../../../models/user";
import { ErrorFormData } from "../../../../models/interfaces/errorForm";
import { ErrorForm } from "../../../../components/ErrorForm";

interface IProps {
    transfer: any;
    isOpen: boolean;
    closeDialog: () => void;
}

export default function ModalHistory({
    isOpen,
    transfer,
    closeDialog,
}: IProps) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [transfers, setTransfers] = useState<any[]>([]);
    const [transferState, setTransferState] = useState<any>(transfer);
    const transferService = new TransferService();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());

    const fetchHistoryTransfers = async () => {
        try {
            setTransfers(await transferService.getHistory(transfer.id));
        } catch (error) {
            toastr.error(t("error"), t("unexpected_error"));
        }
    };

    const restore = async (idVersion: number) => {
        try {
            setLoading(true);
            await transferService.restoreTransfer(transfer.id, idVersion);
            toastr.success(t("success"), t("success_refuse_transfer"));
            fetchHistoryTransfers();
            setTransferState(await transferService.get(transfer.id));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setErrorApi(new ErrorFormData(error.response.data));
        }
    };

    useEffect(() => {
        const fetchHistoryTransfers = async () => {
            try {
                setLoading(true);
                const transferService = new TransferService();
                setTransfers(await transferService.getHistory(transfer.id));
                setLoading(false);
            } catch (error) {
                toastr.error(t("error"), t("unexpected_error"));
            }
        };

        isOpen && fetchHistoryTransfers();
    }, [isOpen, transfer.id, t]);

    const valueHasChanged = (key: string) => {
        const lastTransfer = transfers[0];
        if (lastTransfer && transferState) {
            /*if (key.includes("p.")) {
                const pkey = key.substring(2);
                const lastPassenger = lastTransfer.passengers[0];
                const passenger =
                    transferState.passengers && transferState.passengers[0];
                if (passenger) {
                    if (pkey === "fullname") {
                        if (
                            `${lastPassenger["firstname"]} ${lastPassenger["lastname"]}` !==
                            `${passenger["firstname"]} ${passenger["lastname"]}`
                        )
                            return true;
                    } else {
                        if (lastPassenger[pkey] !== passenger[pkey]) {
                            return true;
                        }
                    }
                }
            } else {*/
                if (lastTransfer[key] !== transferState[key]) {
                    return true;
                }
            //}
        }
        return false;
    };
    return (
        <Modal
            label={t("transfer_history")}
            isOpen={isOpen}
            closeModal={() => closeDialog()}
            maxWidth={"lg"}
        >
            <div className="content-modal-unasign">
                {!loading ? (
                    <>
                        <TableContainer component={Paper}>
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <HeadTableCell align="center"></HeadTableCell>
                                        <HeadTableCell
                                            align="center"
                                            className="client_logo_table"
                                        ></HeadTableCell>
                                        <HeadTableCell align="center">
                                            {t("form.transfer_type")}
                                        </HeadTableCell>
                                        <HeadTableCell align="center">
                                            {t("nb_passengers")}
                                        </HeadTableCell>

                                        <HeadTableCell align="center">
                                            {`${t("lastname")} ${t(
                                                "firstname"
                                            )}`}
                                        </HeadTableCell>
                                        <HeadTableCell align="center">
                                            {t("phone")}
                                        </HeadTableCell>
                                        <HeadTableCell align="center">
                                            {t("email")}
                                        </HeadTableCell>
                                        <HeadTableCell align="center">
                                            {t("departure_location")}
                                        </HeadTableCell>
                                        <HeadTableCell align="center">
                                            {t("arrival_location")}
                                        </HeadTableCell>
                                        <HeadTableCell
                                            align="center"
                                            className="status_list"
                                        >
                                            {t("folder_status")}
                                        </HeadTableCell>
                                        <CheckPermissions
                                            permissions={[
                                                Roles.ADMIN,
                                                Roles.CLIENT,
                                            ]}
                                        >
                                            <HeadTableCell
                                                align="center"
                                                className="history_column"
                                            ></HeadTableCell>
                                        </CheckPermissions>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="list-history-transfer">
                                    <BodyTableRow>
                                        <BodyTableCell align="center">
                                            {`${t(
                                                "updated_at"
                                            )} ${transferState.getHourUpdated()}`}
                                        </BodyTableCell>
                                        <BodyTableCell align="center">
                                            <div className="upload-image-table">
                                                <ImagePreview
                                                    size="litle"
                                                    existingImageUrl={
                                                        transferState.folder
                                                            ?.client?.img_url
                                                    }
                                                    onUpdateImage={() => null}
                                                    disabledForm
                                                />
                                            </div>
                                        </BodyTableCell>
                                        <BodyTableCell
                                            align="center"
                                            className={
                                                valueHasChanged("type")
                                                    ? "has_changed"
                                                    : ""
                                            }
                                        >
                                            {transferState.type}
                                        </BodyTableCell>
                                        <BodyTableCell
                                            align="center"
                                            className={
                                                valueHasChanged("nb_passenger")
                                                    ? "has_changed"
                                                    : ""
                                            }
                                        >
                                            {transferState.nb_passenger}
                                        </BodyTableCell>
                                        <BodyTableCell
                                            align="center"
                                            className={
                                                valueHasChanged("p.fullname")
                                                    ? "has_changed"
                                                    : ""
                                            }
                                        >
                                            <div>
                                                <span>
                                                    {transferState.passengers &&
                                                        transferState.passengers
                                                            .length > 0 &&
                                                        `${transferState.passengers[0].firstname} ${transferState.passengers[0].lastname}`}
                                                </span>
                                                <br />
                                            </div>
                                        </BodyTableCell>
                                        <BodyTableCell
                                            align="center"
                                            className={
                                                valueHasChanged("p.phone")
                                                    ? "has_changed"
                                                    : ""
                                            }
                                        >
                                            <div>
                                                <span>
                                                    {transferState.passengers &&
                                                        transferState.passengers
                                                            .length > 0 &&
                                                        transferState
                                                            .passengers[0]
                                                            .phone}
                                                </span>
                                                <br />
                                            </div>
                                        </BodyTableCell>
                                        <BodyTableCell
                                            align="center"
                                            className={
                                                valueHasChanged("p.email")
                                                    ? "has_changed"
                                                    : ""
                                            }
                                        >
                                            <div>
                                                <span>
                                                    {transferState.passengers &&
                                                        transferState.passengers
                                                            .length > 0 &&
                                                        transferState
                                                            .passengers[0]
                                                            .email}
                                                </span>
                                                <br />
                                            </div>
                                        </BodyTableCell>
                                        <BodyTableCell
                                            align="center"
                                            className={
                                                valueHasChanged(
                                                    "departure_location"
                                                )
                                                    ? "has_changed"
                                                    : ""
                                            }
                                        >
                                            {transferState.departure_location}
                                        </BodyTableCell>
                                        <BodyTableCell
                                            align="center"
                                            className={
                                                valueHasChanged(
                                                    "arrival_location"
                                                )
                                                    ? "has_changed"
                                                    : ""
                                            }
                                        >
                                            {transferState.arrival_location}
                                        </BodyTableCell>
                                        <BodyTableCell
                                            align="center"
                                            className={`tag-status ${
                                                valueHasChanged("status")
                                                    ? "has_changed"
                                                    : ""
                                            }`}
                                        >
                                            <div
                                                className={`tag-table-section`}
                                                style={{ position: "relative" }}
                                            >
                                                {transferState.status ===
                                                "to_assign" ? (
                                                    <div className="tag-table-content">
                                                        <div
                                                            className="to_assign_tag"
                                                            style={{
                                                                backgroundColor:
                                                                    transferState.getColorStatus(
                                                                        "admin"
                                                                    ),
                                                            }}
                                                        >
                                                            <div className="tag-table">
                                                                <span className="to-upper">
                                                                    {t(
                                                                        "provider_status.pending"
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="tag-table-content">
                                                        <div
                                                            className="tag-table"
                                                            style={{
                                                                backgroundColor:
                                                                    transferState.getColorStatus(
                                                                        "admin"
                                                                    ),
                                                            }}
                                                        >
                                                            <div className="tag-table">
                                                                <span className="to-upper">
                                                                    {t(
                                                                        "admin_status." +
                                                                            transferState.status
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </BodyTableCell>
                                    </BodyTableRow>
                                    {transfers.map(
                                        (
                                            historyTransfer: HistoryTransfer,
                                            index: number
                                        ) => (
                                            <ItemHistoryTransfer
                                                key={`table_history_transfer_id_${historyTransfer.id}`}
                                                historyTransfer={
                                                    historyTransfer
                                                }
                                                index={index}
                                                historyTransfers={transfers}
                                                restore={(id: number) =>
                                                    restore(id)
                                                }
                                            />
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="actions">
                            <Button
                                onClick={() => fetchHistoryTransfers()}
                                className="btn-form btn-icon mr-2 submit-input"
                                variant="contained"
                                color="secondary"
                                size="large"
                                type="button"
                            >
                                {t("refresh")} <Icon>{"refresh"}</Icon>
                            </Button>
                        </div>
                    </>
                ) : transfers.length > 0 && !loading ? (
                    <span>{t("no_record")}</span>
                ) : (
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress />
                    </div>
                )}
            </div>
            <ErrorForm errorApi={errorApi} />
        </Modal>
    );
}
