import {MoreHoriz, Settings} from "@material-ui/icons";
import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {NotificationUser} from "../../../../models/notificationUser";
import GreyButton from "../../../grey-button/GreyButton";
import ImagePreview from "../../../image-preview/ImagePreview";
import "./ListNotifications.scss";
import FiltersNotifications from "../../../../containers/notification/list-notifications/FiltersNotifications/FiltersNotifications";
import {FiltersEnabled} from "../../../../models/utils/filters/filter-enabled";
import * as notificationHelper from "../../../../utils/NotificationHelper";

interface IProps {
    notifications?: NotificationUser[];
    show: boolean;
    filtersEnabled: FiltersEnabled;
    setFilters: (filters: FiltersEnabled) => void;
    close: () => void;
}

export default function ListNotification({notifications, show, filtersEnabled, setFilters, close}: IProps) {
    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useTranslation();

    const displayContent = (content: string) => {
        return content.length > 65 ? content.slice(0, 65) + "..." : content;
    };

    return show ? (
        <>
            <div className="modal-notif-mask" onClick={()=>close()}></div>
            <div className="modal-notif">
                <div className="header-notif">
                    <div className="title-section">
                        <span className="to-upper title">{t("notifications")}</span>
                        <div className="settings-icon">
                            <Settings style={{fontSize: 20}}/>
                        </div>
                    </div>
                    <FiltersNotifications
                        colorFilters={"menu"}
                        filtersEnabled={filtersEnabled}
                        setFilters={setFilters}
                    />
                </div>
                <div className="notifications">
                    {notifications && notifications.length > 0 ? (
                        notifications.filter((notif) => {
                            if (filtersEnabled.list && filtersEnabled.list.length !== 0) {
                                return filtersEnabled.list[0].value === notif.is_active
                            } else return notif
                        }).map((notif: NotificationUser) => {
                            return (
                                <div
                                    onClick={() => notificationHelper.showNotification(notif, dispatch, history)}
                                    key={`notification_${notif.id}`}
                                    className={
                                        notif.is_active ? "notif active" : "notif"
                                    }
                                >
                                    <div className="infos">
                                        <div className="logo">
                                            <ImagePreview
                                                size="small"
                                                onUpdateImage={() => null}
                                                disabledForm
                                            />
                                        </div>
                                        <div className="texts">
                                            <div className="user">
                                                Collaborateur 1
                                            </div>
                                            <div className="content">
                                                {displayContent(notif.content)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="more">
                                        <div className="more-icon">
                                            <MoreHoriz style={{fontSize: 20}}/>
                                        </div>
                                        <div className="hours">
                                            {notif.created_at
                                                .toLocaleTimeString()
                                                .slice(0, 5)}
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="no-notifs">{t("no_notif")}</div>
                    )}
                </div>
                <div className="footer">
                    <GreyButton
                        onClick={() =>
                            history.push({
                                pathname: "/app/notification",
                                state: {notifications: notifications},
                            })
                        }
                        className="btn-rounded"
                    >
                        <span className="to-upper"></span> {t("see_all_notif")}
                    </GreyButton>
                </div>
            </div>
        </>
    ) : (
        <></>
    );
}
