import React, {useState, useEffect, useCallback} from "react";
import {
    CircularProgress,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {DataPaginate} from "../../../models/utils/data-paginate";
import Pagination from "../../../components/pagination/pagination";
import GlobalSearch from "../../../components/global-search/globalSearch";
import Paper from "@material-ui/core/Paper";
import KpiContentSeparator from "../../../components/kpi-content-separator/KpiContentSeparator";
import ItemFolder from "./ItemFolder";
import FolderService from "../../../services/folder-service";
import {Folder} from "../../../models/folder";
import HeadTableCell from "../../../components/table/HeadTableCell";
import {Roles, User} from "../../../models/user";
import {FiltersEnabled} from "../../../models/utils/filters/filter-enabled";
import {FolderFilters} from "../../../models/utils/filters/folders/folder-filters";
import FiltersFolders from "./FiltersFolders";
import {debounce} from "lodash";

interface IProps {
    user: User;
}

function ListFolders({user}: IProps) {
    const {t} = useTranslation();
    const [dataPaginate, setDataPaginate] = useState(new DataPaginate());
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState<FolderFilters>();
    const [filtersEnabled, setFiltersEnabled] = useState<FiltersEnabled>(new FiltersEnabled());
    const [date, setDate] = useState(new Date());

    const fetchAll = async () => {
        if (!loading) setLoading(true);
        try {
            const dataService = new FolderService();
            console.log(await dataService.all(currentPage, search, null, filtersEnabled))
            setDataPaginate(await dataService.all(currentPage, search, null, filtersEnabled));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log("error", error);
        }
    };

    const debounceQuery = useCallback(
        debounce(fetchAll, 500),
        [currentPage, search, filtersEnabled, date]
    );

    useEffect(() => {
        debounceQuery();
        return debounceQuery.cancel;
    }, [currentPage, search, filtersEnabled, date, debounceQuery]);

    useEffect(() => {
        const fetchFilters = async () => {
            try {
                const folderService = new FolderService();
                setFilters(await folderService.getFilter());
            } catch (e) {
                console.log(e);
            }
        }
        fetchFilters();
    }, [])

    function onPageChange(newPage?: number) {
        if (newPage) {
            setLoading(true);
            setCurrentPage(newPage);
        }
    }

    const handleFiltersChange = (filters: FiltersEnabled) => {
        setFiltersEnabled(filters);
        setCurrentPage(1);
    }

    function renderTableAdmin() {
        return (
            <TableContainer component={Paper}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <HeadTableCell align="center">
                                {t("num_folder")}
                            </HeadTableCell>
                            <HeadTableCell align="center">
                                {t("folder_status")}
                            </HeadTableCell>
                            <HeadTableCell align="center">
                                {t("client")}
                            </HeadTableCell>
                            <HeadTableCell align="center">
                                {t("beneficiary")}
                            </HeadTableCell>
                            <HeadTableCell align="center">
                                {t("folder_name")}
                            </HeadTableCell>
                            <HeadTableCell align="center">
                                {t("referent_sparklane")}
                            </HeadTableCell>
                            <HeadTableCell align="center">
                                {t("referent_client")}
                            </HeadTableCell>
                            <HeadTableCell align="center">
                                {t("start_end_date")}
                            </HeadTableCell>
                            <HeadTableCell align="center">
                                {t("number_vehicles")}
                            </HeadTableCell>
                            <HeadTableCell align="center">
                                {t("number_drivers")}
                            </HeadTableCell>
                            <HeadTableCell align="center">
                                {t("number_passenger")}
                            </HeadTableCell>
                            <HeadTableCell align="center">
                                {t("price_ht")}
                            </HeadTableCell>
                            <HeadTableCell align="center">
                                {t("price_ttc")}
                            </HeadTableCell>
                            <HeadTableCell align="center">
                                {t("net_margin")}
                            </HeadTableCell>
                            <HeadTableCell align="center">
                                {t("nav.transfers")}
                            </HeadTableCell>
                            <HeadTableCell align="center"/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataPaginate.data
                        .map(
                            (folder: Folder, index: number) => (
                                <ItemFolder
                                    key={`table_client_id_${folder.id}`}
                                    folder={folder}
                                    user={user}
                                    onDeleteSuccess={() => fetchAll()}
                                />
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    function renderTableClient() {
        return (
            <TableContainer component={Paper} className="table-scroll">
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <HeadTableCell align="center">
                                {t("num_bill")}
                            </HeadTableCell>
                            <HeadTableCell align="center">
                                {t("folder_status")}
                            </HeadTableCell>
                            <HeadTableCell align="center">
                                {t("client")}
                            </HeadTableCell>
                            <HeadTableCell align="center">
                                {t("referent_name")}
                            </HeadTableCell>
                            <HeadTableCell align="center">
                                {t("referent_sparklane")}
                            </HeadTableCell>
                            <HeadTableCell align="center">
                                {t("managment")}
                            </HeadTableCell>
                            <HeadTableCell align="center">
                                {t("number_transfer")}
                            </HeadTableCell>
                            <HeadTableCell align="center">
                                {t("form.start_date")}
                            </HeadTableCell>
                            <HeadTableCell align="center">
                                {t("form.end_date")}
                            </HeadTableCell>
                            <HeadTableCell align="center">
                                {t("price_ttc")}
                            </HeadTableCell>
                            
                            <HeadTableCell align="center"></HeadTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataPaginate.data.map(
                            (folder: Folder, index: number) => (
                                <ItemFolder
                                    key={`table_client_id_${folder.id}`}
                                    folder={folder}
                                    user={user}
                                    onDeleteSuccess={() => fetchAll()}
                                />
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    return (
        <>
            <GlobalSearch
                onSearch={(value: string) => setSearch(value)}
                onResetFilter={() => setDate(new Date())}
            />
            <FiltersFolders
                filtersEnabled={filtersEnabled}
                filters={filters}
                setFilters={(filters) => handleFiltersChange(filters)}
            />
            <KpiContentSeparator/>
            {dataPaginate &&
            dataPaginate.data?.length &&
            !loading ? (
                <>
                    {user.role === Roles.CLIENT
                        ? renderTableClient()
                        : renderTableAdmin()}
                    <Pagination
                        dataPaginate={dataPaginate}
                        onPageChange={(page) => onPageChange(page)}
                    />
                </>
            ) : loading ? (
                <CircularProgress/>
            ) : (
                <div>{t("no_items")}</div>
            )}
        </>
    );
}

export default ListFolders;
