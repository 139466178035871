import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Vehicle } from "../../../models/vehicle";
import { useForm } from "react-hook-form";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import { useHistory } from "react-router-dom";
import VehicleService from "../../../services/vehicle-service";
import { toastr } from "react-redux-toastr";
import SubmitComponent from "../../../components/submit-component/SubmitComponent";
import { ErrorForm } from "../../../components/ErrorForm";
import { VehicleBrand } from "../../../models/vehicle-brand";
import { VehicleType } from "../../../models/vehicle-type";
import { VehicleModel } from "../../../models/vehicle-model";
import { VehicleOption } from "../../../models/vehicle-option";
import {
    FormControl,
    InputLabel,
    Checkbox,
    FormControlLabel,
} from "@material-ui/core";
import { InteriorColor } from "../../../models/interior-color";
import { ExteriorColor } from "../../../models/exterior-color";
import ImgExteriorColor from "../../../components/vehicles/img-exterior-vehicle/ImgExteriorColor";
import ImgInteriorVehicle from "../../../components/vehicles/img-interior-vehicle/ImgInteriorVehicle";
import { Provider } from "../../../models/provider";
import InputControlled from "../../../components/fields-controlled/InputControlled";
import FormSection from "../../../components/form-section/FormSection";
import "./FormVehicle.scss";
import AutocompleteControlled from "../../../components/fields-controlled/AutocompleteControlled";
import ImagePreview from "../../../components/image-preview/ImagePreview";

interface IFormProps {
    brands: Array<VehicleBrand>;
    vehicleTypes: Array<VehicleType>;
    vehicleModels: Array<VehicleModel>;
    interiorColors: Array<InteriorColor>;
    exteriorColors: Array<ExteriorColor>;
    vehicleOptions: Array<VehicleOption>;
    provider: Provider;
    vehicle?: Vehicle;
}

interface IPropsFormVehicle {
    brand_id: number;
    model_id: number | null;
    year: number;
    seats_available: number;
    licence_plate: string;
    interior_type: number | null;
    interior_color_id: number;
    exterior_color_id: number;
    provider_id: number;
    vehicle_type_id: number;
    option_ids: Array<number>;
}

export default function FormVehicle({
    vehicle,
    brands,
    vehicleTypes,
    vehicleModels,
    exteriorColors,
    interiorColors,
    vehicleOptions,
    provider,
}: IFormProps) {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        errors,
        setValue,
        control,
    } = useForm<IPropsFormVehicle>();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [disabledForm, setDisabledForm] = useState(false);
    const [imagePreviewBrand] = useState<any>(null);
    const history = useHistory();
    const [
        correspondingVehicleModels,
        setCorrespondingVehicleModels,
    ] = useState<VehicleModel[]>([]);
    const [interiorColor, setInteriorColor] = useState<InteriorColor>();
    const [exteriorColor, setExteriorColor] = useState<ExteriorColor>();
    const [vehicleType, setVehicleType] = useState<VehicleType>();
    const [vehicleBrand, setVehicleBrand] = useState<VehicleBrand>();
    const [vehicleModel, setVehicleModel] = useState<VehicleModel>();
    const [interiorType, setInteriorType] = useState<any>();
    const [optionIds, setOptionIds] = useState<number[]>([]);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [loadingContent, setLoadingContent] = useState(true);
    const [isActivateButton,setIsActivateButton]=useState(true)
    const interiorTypeList: any[] = [
        {
            id: 1,
            label: t("leather"),
        },
        {
            id: 2,
            label: t("textile"),
        },
    ];

    useEffect(() => {
        register("vehicle_type_id");
        register("brand_id");
        register("model_id");
        register("exterior_color_id");
        register("interior_type");
        register("interior_color_id");
        register("option_ids");
        register("provider_id");
        setValue("provider_id", provider.id);
    }, [register, provider, setValue]);

    useEffect(() => {
        const loadCorrespondingModels = (brandId: number) => {
            setCorrespondingVehicleModels(
                vehicleModels.filter(
                    (vehicleModel: VehicleModel) =>
                        vehicleModel.brand_id === brandId
                )
            );
        };
        const loadExteriorColor = (exteriorColorId: number) => {
            setExteriorColor(
                exteriorColors.find(
                    (exteriorColor: ExteriorColor) =>
                        exteriorColor.id === exteriorColorId
                )
            );
            setValue("exterior_color_id", exteriorColorId);
        };

        const loadInteriorType = (interiorTypeId: number) => {
            const interiorType = interiorTypeList.find(
                (item) => item.id === interiorTypeId
            );
            setInteriorType(interiorType);
            setValue("interior_type", interiorType ? interiorType.id : null);
        };

        const loadInteriorColor = (interiorColorId: number) => {
            setInteriorColor(
                interiorColors.find(
                    (interiorColor: InteriorColor) =>
                        interiorColor.id === interiorColorId
                )
            );
            setValue("interior_color_id", interiorColorId);
        };

        const loadVehicleType = (vehicleTypeId: number) => {
            setVehicleType(
                vehicleTypes.find(
                    (vehicleType: VehicleType) =>
                        vehicleType.id === vehicleTypeId
                )
            );
            setValue("vehicle_type_id", vehicleTypeId);
        };

        const loadVehicleBrand = (brandId: number) => {
            setVehicleBrand(
                brands.find((brand: VehicleBrand) => brand.id === brandId)
            );
            setValue("brand_id", brandId);
        };

        const loadVehicleModel = (modelId?: number) => {
            setVehicleModel(
                vehicleModels.find(
                    (model: VehicleModel) => model.id === modelId
                )
            );
            setValue("model_id", modelId ? modelId : -1);
        };

        if (vehicle && loadingContent) {
            loadCorrespondingModels(vehicle.brand_id);
            loadVehicleType(vehicle.vehicle_type_id);
            loadVehicleBrand(vehicle.brand_id);
            loadVehicleModel(vehicle.model_id);
            loadExteriorColor(vehicle.exterior_color_id);
            loadInteriorType(vehicle.interior_type);
            loadInteriorColor(vehicle.interior_color_id);
            setValue("brand_id", vehicle.brand_id);
            setValue("model_id", vehicle.model_id);
            setValue("exterior_color_id", vehicle.exterior_color_id);
            setValue("interior_type", vehicle.interior_type);
            setValue("interior_color_id", vehicle.interior_color_id);
            setValue("provider_id", vehicle.provider_id);
            const vehicleOptionsIds =
                vehicle && vehicle.options
                    ? vehicle.options.map((option) => option.id)
                    : [];
            setOptionIds(vehicleOptionsIds);
            setValue("option_ids", vehicleOptionsIds);
        }
        setLoadingContent(false);
    }, [
        vehicle,
        brands,
        vehicleModels,
        exteriorColors,
        interiorColors,
        vehicleTypes,
        interiorTypeList,
        loadingContent,
        setValue,
    ]);

    const onSubmit = async (data: any, e: any) => {
        setIsActivateButton(false)
        setErrorApi(new ErrorFormData());
        const vehicleService = new VehicleService();
        setLoadingSubmit(true);
        setDisabledForm(true);
        try {
            if (vehicle) {
                await vehicleService.update(vehicle.id, data);
                toastr.success(t("success"), t("success_updated_vehicle"));
            } else {
                await vehicleService.create(data);
                toastr.success(t("success"), t("success_add_vehicle"));
            }
            history.push("/app/vehicles");
            setLoadingSubmit(false);
        } catch (error) {
            setDisabledForm(false);
            setLoadingSubmit(false);
            setErrorApi(new ErrorFormData(error.response.data));
        }
        setIsActivateButton(true)
    };

    const updateCorrespondingModels = (brandId: number) => {
        setCorrespondingVehicleModels(
            vehicleModels.filter(
                (vehicleModel: VehicleModel) =>
                    vehicleModel.brand_id === brandId
            )
        );
        updateVehicleModel();
    };

    const updateExteriorColor = (extColor: ExteriorColor) => {
        setExteriorColor(extColor);
        setValue("exterior_color_id", extColor.id);
    };

    const updateInteriorType = (interiorType: any) => {
        setInteriorType(interiorType);
        setValue("interior_type", interiorType.id);
    };

    const updateInteriorColor = (intColor: InteriorColor) => {
        setInteriorColor(intColor);
        setValue("interior_color_id", intColor.id);
    };

    const updateVehicleType = (v: VehicleType) => {
        setVehicleType(v);
        setValue("vehicle_type_id", v.id);
    };

    const updateVehicleBrand = (b: VehicleBrand) => {
        setVehicleBrand(b);
        setValue("brand_id", b.id);
        updateCorrespondingModels(b.id);
    };

    const updateVehicleModel = (m?: VehicleModel) => {
        setVehicleModel(m);
        setValue("model_id", m ? m.id : null);
        setValue("model", m ? m : null);
    };

    const changeOption = (optionId: number, value: string) => {
        if (value === "true") {
            const newOptionIds = [...optionIds.filter((id) => id !== optionId)];
            setOptionIds(newOptionIds);
            setValue("option_ids", newOptionIds);
        } else {
            const newOptionIds = [...optionIds, optionId];
            setOptionIds(newOptionIds);
            setValue("option_ids", newOptionIds);
        }
    };

    const hasOptionId = (optionId: number) => {
        return optionIds?.find((id) => id === optionId) !== undefined;
    };

    return loadingContent === false ? (
        <form
            className="provider-vehicle-form"
            onSubmit={handleSubmit(onSubmit)}
        >
            <FormSection title={t("car")}>
                <div className="flex_row mgl2 mgl2">
                    <AutocompleteControlled
                        label={t("vehicle_type") + "*"}
                        name="vehicle_type"
                        disabled={disabledForm}
                        listItems={vehicleTypes ? vehicleTypes : []}
                        value={vehicleType ? vehicleType : null}
                        required={true}
                        getOptionLabel={(option: any) => option.label}
                        control={control}
                        errors={errors}
                        onChange={(vehicleType: any) =>
                            updateVehicleType(vehicleType)
                        }
                    />

                    <AutocompleteControlled
                        label={t("brand") + "*"}
                        name="brand"
                        disabled={disabledForm}
                        listItems={brands ? brands : []}
                        value={vehicleBrand ? vehicleBrand : null}
                        required={true}
                        getOptionLabel={(option: any) => option.name}
                        control={control}
                        errors={errors}
                        onChange={(brand: any) => updateVehicleBrand(brand)}
                    />

                    {imagePreviewBrand ? (
                        <div className="preview_image">
                            <img src={imagePreviewBrand} alt="" />
                        </div>
                    ) : (
                        ""
                    )}

                    <AutocompleteControlled
                        label={t("model") + "*"}
                        name="model"
                        disabled={disabledForm}
                        listItems={
                            correspondingVehicleModels
                                ? correspondingVehicleModels
                                : []
                        }
                        value={vehicleModel ? vehicleModel : null}
                        required={true}
                        getOptionLabel={(option: any) => option.label}
                        control={control}
                        errors={errors}
                        onChange={(model: any) => updateVehicleModel(model)}
                    />

                    <div className="field-form">
                        <InputControlled
                            name="year"
                            label={t("year") + "*"}
                            value={vehicle ? vehicle.year : ""}
                            required={true}
                            disabled={disabledForm}
                            control={control}
                            errors={errors}
                        />
                    </div>

                    <div className="field-form">
                        <InputControlled
                            name="licence_plate"
                            label={t("licence_number") + "*"}
                            value={vehicle ? vehicle.licence_plate : ""}
                            required={true}
                            disabled={disabledForm}
                            control={control}
                            errors={errors}
                        />
                    </div>
                </div>
            </FormSection>
            <FormSection title={t("passengers")} className="mgt1" marginTitle>
                <div className="flex_row mgl2">
                    <div className="field-form">
                        <InputControlled
                            name="seats_available"
                            label={t("number_of_seats") + "*"}
                            value={vehicle ? vehicle.seats_available : ""}
                            required={true}
                            disabled={disabledForm}
                            control={control}
                            errors={errors}
                        />
                    </div>
                </div>
            </FormSection>
            <FormSection title={t("esthetics")} className="mgt1" marginTitle>
                <div className="display_row mgl2 col-12 esthetics-vehicle no-gutters-left-col">
                    <FormControl className="col-12 col-lg-3 with-previous">
                        <AutocompleteControlled
                            label={t("exterior_colors") + "*"}
                            name="exterior_color"
                            disabled={disabledForm}
                            listItems={exteriorColors ? exteriorColors : []}
                            value={exteriorColor ? exteriorColor : null}
                            required={true}
                            getOptionLabel={(option: any) => option.color}
                            control={control}
                            errors={errors}
                            onChange={(exteriorColor: any) =>
                                updateExteriorColor(exteriorColor)
                            }
                        />
                        {exteriorColor ? (
                            <div className="preview_image">
                                <ImgExteriorColor
                                    exteriorColor={exteriorColor}
                                    renderText={false}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                    </FormControl>

                    <FormControl className="col-12 col-lg-3">
                        <AutocompleteControlled
                            label={t("interior_type") + "*"}
                            name="interior_type_item"
                            disabled={disabledForm}
                            listItems={interiorTypeList}
                            value={interiorType ? interiorType : null}
                            required={true}
                            getOptionLabel={(option: any) => option.label}
                            control={control}
                            errors={errors}
                            onChange={(interiorType: any) =>
                                updateInteriorType(interiorType)
                            }
                        />
                    </FormControl>

                    <FormControl className="col-12 col-lg-3 with-previous">
                        <AutocompleteControlled
                            label={t("interior_colors") + "*"}
                            name="interior_color"
                            disabled={disabledForm}
                            listItems={interiorColors ? interiorColors : []}
                            value={interiorColor ? interiorColor : null}
                            required={true}
                            getOptionLabel={(option: any) => option.color}
                            control={control}
                            errors={errors}
                            onChange={(interiorColor: any) =>
                                updateInteriorColor(interiorColor)
                            }
                        />
                        {interiorColor && interiorType ? (
                            <div className="preview_image">
                                <ImgInteriorVehicle
                                    interiorColor={interiorColor}
                                    interiorType={
                                        interiorType ? interiorType.id : null
                                    }
                                    renderText={false}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                    </FormControl>
                </div>
            </FormSection>
            <FormSection title={t("vehicle")} className="mgt1" marginTitle>
                <div className="options-vehicle mgl2 col-12 no-gutters-left-col">
                    <InputLabel className="label-options-vehicle">
                        {t("vehicle_options")}
                    </InputLabel>
                    {vehicleOptions.map((option) => {
                        return (
                            <FormControlLabel
                                key={`form_vehicle_option_id_${option.id}`}
                                control={
                                    <Checkbox
                                        checked={hasOptionId(option.id)}
                                        value={hasOptionId(option.id)}
                                        onChange={(e) =>
                                            changeOption(
                                                option.id,
                                                e.target.value
                                            )
                                        }
                                        name="option_id"
                                    />
                                }
                                label={option.label}
                            />
                        );
                    })}
                </div>
            </FormSection>
            <FormSection title="Attestation du véhicule" className="mgt1" marginTitle>
                <div className="col-12 col-md-auto">
                    <div className="form-group">
                        <label className="bold-label">{t("photo")} (max: 2Mo)</label>
                        <div className="flex_row">
                            <input
                                type="hidden"
                                name="attestation_base64"
                                ref={register()}
                            />
                            <ImagePreview
                                disabledForm={disabledForm}
                                existingImageUrl={vehicle?.attestation_url}
                                canUpdate={true}
                                onUpdateImage={(imgBase64) => {
                                    setValue("attestation_base64", imgBase64);
                                }}
                            />
                        </div>
                    </div>
                   
                </div>
            </FormSection>
            <SubmitComponent
                loading={loadingSubmit}
                text={t("form.validate")}
                isActivate={isActivateButton}
            />
            <ErrorForm errorApi={errorApi} />
        </form>
    ) : (
        <></>
    );
}
