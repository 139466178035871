import { createStyles, TableCell, Theme, withStyles } from "@material-ui/core";
import React from "react";

interface IHeadTableCell {
    className?: string;
    align?: any;
    children?: any;
    width?: number;
}

const HeadTableCell = ({
    className = "testTh",
    align = "center",
    children,
    width
}: IHeadTableCell) => {
    return (
        <StyledTableCell className={className} align={align} style={width ? {minWidth: width} : {}}>
            {children}
        </StyledTableCell>
    );
};

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#F6F8FA",
            color: theme.palette.common.black,
            height: "65px",
            padding: "0 7px",
            fontWeight: "normal",
            border: "none",
            lineHeight: "18px",
        },
        root: {
            fontSize: "14px",
        },
    })
)(TableCell);

export default HeadTableCell;
