import React from "react";
import { ReactSVG } from "react-svg";
import './licencePlate.scss';

interface IProps {
    licencePlate?: string;
}

const LicencePlate = (props:IProps) => {
    return (
        <div className="container-plate">
            <div className="licence-plate">
                <ReactSVG src="/images/licence_plate.svg" />
                <div className="text-plate">{props.licencePlate}</div>
            </div>
        </div>
    );

}

export default LicencePlate;