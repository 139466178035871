import { Provider } from "../models/provider";
import { DataPaginate } from "../models/utils/data-paginate";
import { ExportType } from "../components/content-section/export-action/ExportAction";
import ProviderService from "./provider-service";
import ClientService from "./client-service";
import { Client } from "../models/client";
import TransferService from "./transfer-service";
import { Transfer } from "../models/transfer";
import InvoiceService from "./invoice-service";
import { Invoice } from "../models/invoice";
import {Ticket} from "../models/ticket";
import TicketService from "./ticket-service";
import { Assignment } from "../models/assignment";
import { Roles, User } from "../models/user";

export default class CsvService {
    async getData(exportType: ExportType, translate: any) {
        if (exportType === ExportType.Provider) {
            const providerService = new ProviderService();
            const dataPaginate = await providerService.all(null, null, "all");

            return this.buildCsvDataProviders(dataPaginate, translate);
        }
        if (exportType === ExportType.Client || exportType === ExportType.ClientForClient) {
            const clientService = new ClientService();
            const dataPaginate = await clientService.all(null, null, "all");

            return this.buildCsvDataClients(exportType, dataPaginate, translate);
        }
        if (exportType === ExportType.Transfer) {
            const transferService = new TransferService();
            const dataPaginate = await transferService.all(null, null, null);

            return this.buildCsvDataTransfers(dataPaginate, translate);
        }
        if (exportType === ExportType.Ticket) {
            const ticketService = new TicketService();
            const dataPaginate = await ticketService.all(null, null, null);

            return this.buildCsvDataTickets(dataPaginate, translate);
        }
    }

    private buildCsvDataProviders(dataPaginate: DataPaginate, translate: any) {
        const header = [
            translate("business_name"),
            translate("email"),
            translate("phone"),
            translate("number_vehicles"),
            translate("number_drivers"),
            translate("type"),
            translate("adjucication"),
        ];

        return [
            header,
            ...dataPaginate.data.map((provider: Provider) => [
                provider.company_name,
                provider.email,
                provider.phone,
                provider.nb_vehicles,
                provider.nb_drivers,
                provider.provider_type?.label,
                provider.is_tender,
            ]),
        ];
    }

    private buildCsvDataTickets(dataPaginate: DataPaginate, translate: any) {
        const header = [
            translate("title"),
            translate("description"),
            translate("email"),
            translate("type"),
            translate("state"),
            translate("created_at"),
        ];

        return [
            header,
            ...dataPaginate.data.map((ticket: Ticket) => [
                ticket.title,
                ticket.description,
                ticket.email,
                ticket.type,
                ticket.state,
                ticket.created_at,
            ]),
        ];
    }

    // private buildCsvDataTransfers(dataPaginate: DataPaginate, translate: any) {
    //     const header = [
    //         translate("ref"),
    //         translate("client"),
    //         translate("nb_passengers"),
    //         translate("departure_date"),
    //         translate("departure_time"),
    //         translate("departure_location"),
    //         translate("drivers"),
    //         translate("arrival_location"),
    //         translate("notes"),
    //         translate("folder_status"),
    //         translate("amount"),
    //     ];

    //     return [
    //         header,
    //         ...dataPaginate.data.map((transfer: Transfer) => [
    //             transfer.folder?.code + " " + transfer.code,
    //             transfer.passengers &&
    //                 transfer.passengers[0]?.lastname +
    //                     " " +
    //                     transfer.passengers &&
    //                 transfer.passengers[0]?.firstname,
    //             transfer.nb_passenger,
    //             transfer.departure_datetime?.toLocaleDateString(),
    //             transfer.departure_datetime?.toLocaleTimeString(),
    //             transfer.departure_location,
    //             "", //todo return assigned drivers
    //             transfer.arrival_location,
    //             transfer.infos,
    //             translate("status." + transfer.status),
    //         ]),
    //     ];
    // }

    private buildCsvDataTransfers(dataPaginate: DataPaginate, translate: any) {
        const getDriversName = (transfer: Transfer) => {
            let driversName: string = "";
            transfer?.assignments?.map((assignment: Assignment) => {
                if (assignment?.response?.driver)
                    driversName = `${driversName}${assignment.response.driver.last_name} ${assignment.response.driver.first_name} \n`;
            });
            return driversName === "" ? "" : driversName;
        };

        const getDriversPhone = (transfer: Transfer) => {
            let driversPhone: string = "";
            transfer?.assignments?.map((assignment: Assignment) => {
                if (assignment?.response?.driver)
                    driversPhone = `${driversPhone}+${assignment.response.driver.phone} \n`;
            });
            return driversPhone === "" ? "" : driversPhone;
        };

        function getAcceptedAssignment(transfer: Transfer) {
            return transfer.assignments?.find( (assignment: Assignment) =>  assignment.isAccepted())
        }
        
        const header = [
            translate("ref"),
            translate("client"),
            translate("nb_passengers"),
            translate("vehicle_type"),
            translate("licence_number"),
            translate("departure_date"),
            translate("departure_time"),
            translate("departure_location"),
            translate("type"),
            translate("drivers"),
            translate("drivers_phone"),
            translate("arrival_location"),
            translate("folder_status"),
            translate("last_update"),
            translate("note"),
        ];

        return [
            header,
            ...dataPaginate.data.map((transfer: Transfer) => [
                transfer.folder?.code + " " + (transfer.code || ''),
                transfer.passengers && transfer.passengers.length !== 0 ? transfer.passengers[0]?.lastname + " " + transfer.passengers[0]?.firstname : '',
                transfer.nb_passenger,
                transfer.vehicle_type?.label || '',
                getAcceptedAssignment(transfer)  ?  getAcceptedAssignment(transfer)?.response?.vehicle?.licence_plate : '',
                transfer.departure_datetime?.toLocaleDateString(),
                transfer.departure_datetime?.toLocaleTimeString(),
                transfer.departure_location,
                translate(`transfer_type.${transfer.type}`),
                getDriversName(transfer),
                getDriversPhone(transfer),
                transfer.arrival_location,
                translate("status." + transfer.status),
                `${transfer.updated_at.toLocaleDateString()} ${translate("at")}  ${transfer.updated_at?.toLocaleTimeString()}`,
                transfer.free_note
            ]),
        ];
    }

    private buildCsvDataClients(exportType: ExportType, dataPaginate: DataPaginate, translate: any) {

        if (exportType === ExportType.ClientForClient) {
            const header = [
                translate("company_name"),
                translate("management_entity"),
                translate("referent_name"),
                translate("email"),
                translate("form.phone_number"),
                translate("form.mobile_number"),
            ];
    
            return [
                header,
                ...dataPaginate.data.map((client: Client) => [
                    client.company_name,
                    client.division?.label,
                    client.referent_name,
                    client.email,
                    client.phone_number,
                    client.mobile_number,
                ]),
            ];
        }
        //default render for admin
        const header = [
            translate("company_name"),
            translate("management_entity"),
            translate("referent_name"),
            translate("email"),
            translate("phone"),
            translate("account"),
            translate("adresse"),
            translate("folders"),
        ];

        return [
            header,
            ...dataPaginate.data.map((client: Client) => [
                client.company_name,
                client.division?.label,
                client.referent_name,
                client.email,
                client.getPhoneForList(),
                client.nbAccounts,
                client.nbAddresses,
                client.nbFolders,
            ]),
        ];
    }

    async getInvoices(translate: any, search: string = '', startDate: string = '', endDate: string = '', clients: string = '', status: string = '', order: string = '', dir: string = "asc") {
        const service = new InvoiceService();

        if (status === 'all') {
            status = ''; //filter disabled
        }

        const dataPaginate = await service.all(null, search, "all", null, startDate, endDate, clients, status, order, dir);

        const header = [
            translate("num_invoice"),
            translate("folder"),
            translate("client"),
            translate("bill_date"),
            translate("due_date"),
            translate("status_table"),
            translate("price_ht"),
            translate("price_ttc"),
            translate("net_margin"),
        ];

        return [
            header,
            ...dataPaginate.data.map((invoice: Invoice) => [
                invoice.code,
                invoice.folder?.code,
                invoice.folder?.client?.company_name,
                invoice.sent_at?.toLocaleDateString(),
                invoice.sent_at ? invoice.deadline_at?.toLocaleDateString() : '',
                translate(`invoice_status.${invoice.status}`),
                invoice.price_excl_tax,
                invoice.price_incl_tax,
                invoice.price_margin,
            ]),
        ];

    }
}
