import React, { useEffect, useState } from 'react';
import './Boards.scss';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import KpiContentSeparator from '../../../components/kpi-content-separator/KpiContentSeparator';
import {
    CircularProgress,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Paper,
    Button,
} from "@material-ui/core";
import HeadTableCell from "../../../components/table/HeadTableCell";
import { Transfer } from '../../../models/transfer';
import DashboardService from '../../../services/dashboard-service';
import BodyTableRow from '../../../components/table/BodyTableRow';
import BodyTableCell from '../../../components/table/BodyTableCell';

const UpcomingTransfers: React.FunctionComponent = (props) => {
  const { t } = useTranslation();
  let { url } = useRouteMatch();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [datas, setDatas] = useState<Transfer[]>([]);

  useEffect(() => {
    const fetchAll = async () => {
        try {
            const dataService = new DashboardService();

            setDatas(await dataService.transfers());
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log("error", error);
        }
    };
    fetchAll();
}, []);
  
    function renderItem(item: Transfer) {
        return (
                <BodyTableRow key={item.id}>
                    <BodyTableCell align="center">{item.folder?.code} {item.code}</BodyTableCell>
                    <BodyTableCell align="center">
                        {item.folder?.client?.company_name}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {item.getDateDeparture()} {item.getHourDeparture()}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        {item.getDateArrival()} {item.getHourArrival()}
                    </BodyTableCell>
                    <BodyTableCell align="center">
                        <Button
                            style={{
                                backgroundColor: item.getColorStatus("provider"),
                            }}
                            className="btn-rounded mr-2"
                            variant="contained"
                            onClick={() => 
                                history.push(`${url}/transfer/${item.id}/edit`,)
                            }
                        >
                            {t(`status.client.${item.status}`)}
                        </Button>
                    </BodyTableCell>
                </BodyTableRow>
        );
    }

  return (
      <div className="board">
            <h3>{t("board.upcoming_transfers")}</h3>
            <KpiContentSeparator />

            {loading
                ? <CircularProgress />
                : <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <HeadTableCell align="center">
                                    {t("ref")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("client")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("form.start_date")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("form.end_date")}
                                </HeadTableCell>
                                <HeadTableCell align="center">
                                    {t("folder_status")}
                                </HeadTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {datas.sort((a: any, b: any) => a.departure_datetime - b.departure_datetime).map(
                                (transfer: Transfer) => (
                                    renderItem(transfer)
                                )
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            


      </div>  
  );
}

export default UpcomingTransfers;

