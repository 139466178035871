import React, { useEffect, useState } from "react";
import { Beneficiary } from "../../../models/beneficiary";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import { useHistory } from "react-router-dom";
import BeneficiaryService from "../../../services/beneficiary-service";
import { toastr } from "react-redux-toastr";
import ImagePreview from "../../../components/image-preview/ImagePreview";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import SubmitComponent from "../../../components/submit-component/SubmitComponent";
import { ErrorForm } from "../../../components/ErrorForm";
import InputControlled from "../../../components/fields-controlled/InputControlled";
import FormSection from "../../../components/form-section/FormSection";
import { Client } from "../../../models/client";
import AutocompleteControlled from "../../../components/fields-controlled/AutocompleteControlled";
import { User } from "../../../models/user";
import { connect } from "react-redux";

interface PropsFormBeneficiary {
    logo: string;
    name: string;
    activated: boolean;
    client_id: number;
}

interface FormProps {
    beneficiary?: Beneficiary;
    clients: Client[];
    client_id?: number | null;
    user: User;
}

const FormBeneficiary: React.FunctionComponent<FormProps> = ({
    beneficiary,
    clients,
    client_id,
    user,
}: FormProps) => {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        errors,
        setValue,
        control,
    } = useForm<PropsFormBeneficiary>();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const readOnly = beneficiary?.client && user.isClient() && user.isReader(beneficiary.client) ? true : false;
    const [disabledForm, setDisabledForm] = useState(readOnly);
    const history = useHistory();
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    useEffect(() => {
        register("client_id");
        if (beneficiary && beneficiary?.client && beneficiary?.client.id) {
            setValue("client_id", beneficiary.client.id);
        }
        if (beneficiary?.logo) {
            setValue("img_base64", beneficiary?.logo);
        }
    }, [beneficiary, register, setValue]);

    const [isActivateButton,setIsActivateButton]=useState(true)

    const onSubmit = async (data: any, e: any) => {
        setIsActivateButton(false)
        const beneficiaryService = new BeneficiaryService();
        try {
            setLoadingSubmit(true);
            setDisabledForm(true);
            if (beneficiary) {
                if (client_id) {
                    //keep previous data
                    data.client_id = beneficiary.client_id;
                }
                await beneficiaryService.put(data, beneficiary.id);
                toastr.success(t("success"), t("success_save_benef"));
            } else {
                if (client_id) {
                    //only use predefined client
                    data.client_id = client_id;
                }
                await beneficiaryService.post(data);
                toastr.success(t("success"), t("success_add_benef"));
            }
            client_id ? history.push(`../../client/${client_id}?tab=beneficiaries`) : history.goBack();
        } catch (e) {
            setDisabledForm(false);
            setLoadingSubmit(false);
            setErrorApi(new ErrorFormData({error: t('error_form_image')}));
        }
        setIsActivateButton(true)
    };

    return (
        <>
            <form className="form-react-benef" onSubmit={handleSubmit(onSubmit)}>
                <FormSection title={t("form.identity")} className="mgl2">
                    <div className="row row-md mt-3">
                        <div className="col-12 col-lg-6 col-xl-4">
                            <div className="form-group">
                                <InputControlled
                                    name="name"
                                    label={`${t("form.beneficary_name")} *`}
                                    value={beneficiary ? beneficiary.name : ""}
                                    required={true}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        { !client_id &&
                        <div className="col-8 col-xl-auto">
                            <div className="form-group">
                                <AutocompleteControlled
                                    label={`${t("form.client")} *`}
                                    name="client"
                                    disabled={disabledForm}
                                    listItems={clients ? clients : []}
                                    value={beneficiary ? beneficiary?.client : null}
                                    required={true}
                                    getOptionLabel={(option: any) => option.company_name}
                                    renderOption={(option: any) => (
                                        <React.Fragment>
                                        {option.parent_id && user.isAdmin() ? <span className="spacer"> - </span> : ''}
                                        {option.company_name}
                                        </React.Fragment>
                                    )}
                                    control={control}
                                    errors={errors}
                                    onChange={(e: any) => setValue("client_id", e.id)}
                                />
                            </div>
                        </div>
                        }

                        <div className="col-md-4 col-lg-auto">
                            <div className="form-group">
                                <FormControlLabel
                                    className="switch-label-top mx-0"
                                    control={
                                        <Switch
                                            inputRef={register}
                                            name="activated"
                                            className="switch-ios"
                                            defaultChecked={!!beneficiary?.activated}
                                            disabled={disabledForm}
                                        />
                                    }
                                    label={t("form.activated")}
                                    labelPlacement="top"
                                />
                                {errors.activated && <p>{errors.activated.message}</p>}
                            </div>
                        </div>
                        <div className="col-lg">
                            <div className="form-group flexed-img">
                                <label>{t("logo")} (max: 2Mo)</label>
                                <div className="flex_row">
                                    <input type="hidden" name="img_base64" ref={register()} />
                                    <ImagePreview
                                        disabledForm={disabledForm}
                                        canUpdate={true}
                                        existingImageUrl={beneficiary?.img_url}
                                        onUpdateImage={(imgBase64) => {
                                            setValue("img_base64", imgBase64);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </FormSection>
                {!readOnly &&
                    <SubmitComponent
                        loading={loadingSubmit}
                        isActivate={isActivateButton}
                        text={t("form.validate")}
                    />
                }
                <ErrorForm errorApi={errorApi} />
            </form>
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(FormBeneficiary);
