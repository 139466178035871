import axios, { AxiosResponse } from "axios";

import { getBaseUrl, getParamsUrl } from "./setting-service";
import { DataPaginate } from "../models/utils/data-paginate";
import { Transfer } from "../models/transfer";
import { Provider } from "../models/provider";
import { Assignment } from "../models/assignment";
import { FiltersEnabled } from "../models/utils/filters/filter-enabled";
import { HistoryTransfer } from "../models/history-transfer";
import { Vehicle } from "../models/vehicle";

export default class TransferService {
    all(
        page?: number | null,
        search?: string | null,
        filters?: FiltersEnabled | null,
        folder?: number | null
    ) {
        let paramsUrl = "";

        if (filters)
            paramsUrl = getParamsUrl([
                { key: "page", value: page },
                { key: "search", value: search },
                ...filters.list,
                { key: "folder", value: folder },
            ]);
        else {
            paramsUrl = getParamsUrl([
                { key: "page", value: page },
                { key: "search", value: search },
                { key: "folder", value: folder },
            ]);
        }
        return axios
            .get(`${getBaseUrl()}transfers${paramsUrl}`)
            .then((response: AxiosResponse) => {
                let res = response.data;

                res.data = res.data.map(
                    (client: Object) => new Transfer(client)
                );
                return new DataPaginate(res);
            });
    }

    getTransfersOfProvider(
        provider: number,
        page?: number | null,
        search?: string | null,
        filters?: FiltersEnabled | null,
        limit?: string | null
    ) {
        let paramsUrl = "";

        if (filters)
            paramsUrl = getParamsUrl([
                { key: "page", value: page },
                { key: "search", value: search },
                ...filters.list,
                { key: "provider", value: provider },
                { key: "limit", value: limit },
            ]);
        else {
            paramsUrl = getParamsUrl([
                { key: "page", value: page },
                { key: "search", value: search },
                { key: "provider", value: provider },
                { key: "limit", value: limit },
            ]);
        }
        return axios
            .get(`${getBaseUrl()}providers/${provider}/transfers${paramsUrl}`)
            .then((response: AxiosResponse) => {
                let res = response.data;

                res.data = res.data.map(
                    (transfer: Object) => new Transfer(transfer)
                );
                return new DataPaginate(res);
            });
    }

    getKpisTransfersOfProvider(provider?: number | null) {
        let params = "";
        if (provider) {
            params = "?provider=" + provider;
        }
        return axios
            .get(`${getBaseUrl()}providers/${provider}/transfers/kpis${params}`)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    get(id: number) {
        return axios
            .get(`${getBaseUrl()}transfers/${id}`)
            .then((response: AxiosResponse) => new Transfer(response.data))
            .catch();
    }

    add(body: any) {
        return axios
            .post(`${getBaseUrl()}transfers`, body)
            .then((response: AxiosResponse) => new Transfer(response.data))
            .catch();
    }

    update(id: number, body: any) {
        return axios
            .put(`${getBaseUrl()}transfers/${id}`, body)
            .then((response: AxiosResponse) => new Transfer(response.data))
            .catch();
    }

    delete(id: number) {
        return axios
            .delete(`${getBaseUrl()}transfers/${id}`)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    getKpis(folder?: number | null) {
        let params = "";
        if (folder) {
            params = "?folder=" + folder;
        }
        return axios
            .get(`${getBaseUrl()}transfers/kpis` + params)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    import(folder: number, body: any) {
        return axios
            .post(`${getBaseUrl()}folders/${folder}/transfers/import`, body)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    providers(id: number, page?: number | null, limit?: string | null) {
        let paramsUrl = getParamsUrl([
            { key: "page", value: page },
            { key: "limit", value: limit },
        ]);
        return axios
            .get(`${getBaseUrl()}transfers/${id}/providers${paramsUrl}`)
            .then((response: AxiosResponse) => {
                let res = response.data;
                res.data = res.data.map(
                    (provider: Object) => new Provider(provider)
                );

                return new DataPaginate(res);
            });
    }

    assignments(id: number) {
        return axios
            .get(`${getBaseUrl()}transfers/${id}/assignments`)
            .then((response: AxiosResponse) =>
                response.data.map(
                    (assignment: Object) => new Assignment(assignment)
                )
            )
            .catch();
    }

    assign(id: number, body: any) {
        return axios
            .put(`${getBaseUrl()}transfers/${id}/assignments`, body)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    accept(id: number, body: any) {
        return axios
            .post(`${getBaseUrl()}transfers/${id}/assignments`, body)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    chooseProvider(id: number, assignmentId: number, body: any) {
        return axios
            .put(
                `${getBaseUrl()}transfers/${id}/assignments/${assignmentId}/choose`,
                body
            )
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    unassign(id: number, body: any) {
        return axios
            .put(`${getBaseUrl()}transfers/${id}/assignments/remove`, body)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    tenders() {
        return axios
            .get(`${getBaseUrl()}tenders`)
            .then((response: AxiosResponse) => {
                let res = response.data;
                res.data = res.data.map(
                    (transfer: Object) => new Transfer(transfer)
                );

                return new DataPaginate(res);
            })
            .catch();
    }

    getKpisTenders() {
        return axios
            .get(`${getBaseUrl()}tenders/kpis`)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    acceptTender(id: number, data: any) {
        return axios
            .post(`${getBaseUrl()}tenders/${id}`, data)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    updateTender(id: number, data: any) {
        return axios
            .put(`${getBaseUrl()}tenders/${id}`, data)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    getHistory(id: number) {
        return axios
            .get(`${getBaseUrl()}transfers/${id}/versions`)
            .then((response: AxiosResponse) => {
                const transfers = response.data.map(
                    (client: Object) => new HistoryTransfer(client)
                );
                return transfers;
            })
            .catch();
    }

    restoreTransfer(idTrans: number, idVersion: number) {
        return axios
            .put(
                `${getBaseUrl()}transfers/${idTrans}/transfers-versions/${idVersion}/restore`
            )
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    preventPassenger(id: number) {
        return axios.post(`${getBaseUrl()}transfers/${id}/prevent-passengers`);
    }

    acceptUpdatedTransfer(id: number) {
        return axios
            .post(`${getBaseUrl()}transfers/${id}/confirm`, null)
            .then((response: AxiosResponse) => response.data)
            .catch();
    }

    getMatchingVehicules(transferId: number){
        return axios.get(`${getBaseUrl()}transfers/${transferId}/matching-vehicules`)
            .then((response: AxiosResponse) => response.data.map((vehicule: Object) => new Vehicle(vehicule)))
    }
}
