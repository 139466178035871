import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { ErrorFormData } from "../../../models/interfaces/errorForm";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import SubmitComponent from "../../../components/submit-component/SubmitComponent";
import { ErrorForm } from "../../../components/ErrorForm";
import AutocompleteControlled from "../../../components/fields-controlled/AutocompleteControlled";
import InputControlled from "../../../components/fields-controlled/InputControlled";
import { Passenger, RoutePoint, Transfer } from "../../../models/transfer";
import TransferService from "../../../services/transfer-service";
import { Folder } from "../../../models/folder";
import { VehicleOption } from "../../../models/vehicle-option";
import { VehicleType } from "../../../models/vehicle-type";
import SubFormPassenger from "./Subforms/SubFormPassenger";
import SubFormPoint from "./Subforms/SubFormPoint";
import { Add } from "@material-ui/icons";
import { formatDateTimePicker } from "../../../utils/DateHelper";
import InputDateTimeControlled from "../../../components/fields-controlled/InputDateTimeControlled";
import { Language } from "../../../models/language";
import "./FormTransfer.scss";
import FormSection from "../../../components/form-section/FormSection";
import { Switch } from "@material-ui/core";
import CheckPermissions from "../../../utils/CheckPermissions";
import { Roles } from "../../../models/user";
import { connect } from "react-redux";
import { User } from "../../../models/user";

interface IPropsForm {
    folder_id: number | null;
    folder: Folder | null;
    departure_location: string;
    departure_datetime?: Date | null;
    arrival_location: string;
    arrival_datetime?: Date | null;
    arrival_postal_code: string;
    departure_postal_code?: string;
    infos: string;
    type: any;
    option_ids: number[];
    nb_passenger: number;
    passengers: Passenger[];
    route_points: RoutePoint[];
    is_tender: boolean;
    tender_price?: number;
    min_estimate?: number;
    max_estimate?: number;
}

interface IFormProps {
    transfer?: Transfer;
    folderList: Folder[];
    vehicleTypes: VehicleType[];
    vehicleOptions: VehicleOption[];
    languages: Language[];
    folder?: Folder;
    changeFolder: (folder: Folder) => void;
    user: User;
}

const FormTransfer: React.FunctionComponent<IFormProps> = ({
    transfer,
    folderList,
    vehicleTypes,
    vehicleOptions,
    languages,
    folder,
    changeFolder,
    user,
}: IFormProps) => {
    console.log(transfer)
    const { t } = useTranslation();
    const [isActivateButton, setIsActivateButton] = useState(true);
    const { register, handleSubmit, setValue, getValues, errors, control } =
        useForm<IPropsForm>();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const readOnly =
        transfer?.folder?.client &&
        user.isClient() &&
        user.isReader(transfer.folder.client)
            ? true
            : false;
    const [disabledForm, setDisabledForm] = useState(readOnly);
    const [passengers, setPassengers] = useState<any>(
        transfer?.passengers ? transfer?.passengers : [{}]
    );

    const [routePoints, setRoutePoints] = useState<any>(
        transfer?.route_points ? transfer?.route_points : []
    );
    const [optionIds, setOptionIds] = useState<number[]>([]);
    const [vehicleType, setVehicleType] = useState<VehicleType>(
        vehicleTypes?.filter(
            (v: VehicleType) => v.id === transfer?.vehicle_type_id
        )[0]
    );
    const [errorType, setErrorType] = useState<string>();
    const [errorPostalType, setErrorPostalType] = useState<string>();
    const [errorPriceType, setErrorPriceType] = useState<string>();
    const [isTender, setIsTender] = useState<boolean>(
        transfer && transfer.is_tender ? transfer.is_tender : false
    );
    const [typeTender, setTypeTender] = useState<string>(
        transfer
            ? !transfer.isTender()
                ? "standard"
                : transfer.getTypeTender()
            : "standard"
    );

    let history = useHistory();
    const transferTypes = ["standard", "provision"];
    useEffect(() => {
        register("type");
        register("vehicle_type_id");
        register("option_ids");
    }, [register]);

    useEffect(() => {
        if (transfer?.id) {
            setOptionIds(
                transfer && transfer.options
                    ? transfer.options.map((option) => option.id)
                    : []
            );
            setValue(
                "option_ids",
                transfer && transfer.options
                    ? transfer.options.map((option) => option.id)
                    : []
            );
            setValue("type", transfer.type);
            setValue("vehicle_type_id", transfer.vehicle_type_id);
        }
    }, [transfer, setValue]);
    const onSubmit = async (data: any) => {
        setIsActivateButton(false);
        setErrorApi(new ErrorFormData());
        setErrorType(undefined);
        setErrorPostalType(undefined);
        setErrorPriceType(undefined);
        const min = data.min_estimate && Number(data.min_estimate);
        const max = data.max_estimate && Number(data.max_estimate);
        const fixed = data.fixed_price && Number(data.fixed_price);
        if (!data.type) {
            setErrorType(t("select_transfer_type"));
        } else if (
            data.is_tender &&
            (!data.departure_postal_code || !data.arrival_postal_code)
        ) {
            setErrorPostalType(t("select_transfer_postal_code"));
        } else if (data.is_tender && min && min >= max) {
            setErrorPriceType(t("tender_price_superior"));
        } else if ((data.is_tender && min <= 0) || max <= 0 || fixed <= 0) {
            setErrorPriceType(t("tender_price_incorrect"));
        } else {
            setErrorType(undefined);
            const service = new TransferService();
            try {
                setDisabledForm(true);
                if (transfer?.id) {
                    data = clearTenderFields(data);
                    data.passengers?.map((pass: Passenger, index: number) => {
                        pass.transfer_id = transfer.id;
                    });
                    await service.update(transfer.id, data);
                    toastr.success(t("success"), t("success_update_transfer"));
                    history.push(
                        "/app/folder/" +
                            transfer?.folder_id +
                            "/edit?tab=transfers"
                    );
                } else {
                    if (transfer?.folder_id) {
                        data.folder_id = transfer.folder_id;
                    }
                    await service.add(data);
                    toastr.success(t("success"), t("success_new_transfer"));
                    history.push(
                        "/app/folder/" + data.folder_id + "/edit?tab=transfers"
                    );
                }
                toastr.success(t("success"), t("success_new_transfer"));
            } catch (error) {
                setDisabledForm(false);
                setErrorApi(new ErrorFormData(error.response.data));
            }
        }
        setIsActivateButton(true);
        setErrorApi(new ErrorFormData());
    };

    const clearTenderFields = (data: any) => {
        if (data.is_tender) {
            if (typeTender === "standard") {
                data.tender_price = null;
            } else {
                data.min_estimate = null;
                data.max_estimate = null;
            }
        } else {
            data.tender_price = null;
            data.min_estimate = null;
            data.max_estimate = null;
        }
        return data;
    };
    const changeOption = (optionId: number, value: string) => {
        if (value === "true") {
            const newOptionIds = [...optionIds.filter((id) => id !== optionId)];
            setOptionIds(newOptionIds);
            setValue("option_ids", newOptionIds);
        } else {
            const newOptionIds = [...optionIds, optionId];
            setOptionIds(newOptionIds);
            setValue("option_ids", newOptionIds);
        }
    };
    const hasOptionId = (optionId: number) => {
        return optionIds?.find((id) => id === optionId) !== undefined;
    };

    const deletePassenger = (index: number) => {
        if (passengers) {
            setPassengers(
                passengers.filter((p: Passenger, i: number) => i !== index)
            );
        }
    };

    const updatePassenger = (index: number, key: any, value: any) => {
        passengers[index][key] = value;
    };

    const deletePoint = (index: number) => {
        if (routePoints) {
            let filtered = routePoints.filter(
                (p: RoutePoint, i: number) => i !== index
            );
            setRoutePoints(filtered);
        }
    };

    const updatePoint = (index: number, key: any, value: any) => {
        routePoints[index][key] = value;
    };

    return (
        <>
            <form
                className="form-react-provider"
                onSubmit={handleSubmit(onSubmit)}
            >
                {!transfer ? (
                    <>
                        <div className="provider-form-part">
                            <div className="field-form mgl2">
                                <AutocompleteControlled
                                    label={`${t("form.choose_folder")} *`}
                                    name="folder"
                                    disabled={disabledForm}
                                    listItems={folderList ? folderList : []}
                                    value={null}
                                    required={true}
                                    getOptionLabel={(option: any) =>
                                        option.code +
                                        " - " +
                                        option.name +
                                        " - " +
                                        option.start_date.toLocaleDateString() +
                                        " - " +
                                        option.end_date.toLocaleDateString()
                                    }
                                    onChange={(folder: Folder) =>
                                        changeFolder(folder)
                                    }
                                    control={control}
                                    errors={errors}
                                    width={500}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    ""
                )}
                <FormSection title={t("form.passengers")}>
                    <div className="row row-md">
                        <div className="col-12 col-xxl-auto">
                            <div className="form-group">
                                <FormControl>
                                    <AutocompleteControlled
                                        label={`${t("form.transfer_type")} *`}
                                        name="type"
                                        disabled={disabledForm}
                                        listItems={transferTypes}
                                        value={
                                            transfer?.type ? transfer.type : ""
                                        }
                                        required={true}
                                        getOptionLabel={(type: any) =>
                                            t("transfer_type." + type)
                                        }
                                        control={control}
                                        errors={errors}
                                    />
                                    {errorType && (
                                        <p
                                            style={{
                                                color: "red",
                                                fontSize: "14px",
                                            }}
                                        >
                                            {errorType}
                                        </p>
                                    )}
                                </FormControl>
                            </div>
                        </div>
                        <div className="col-12 col-xxl-auto">
                            <div className="form-group">
                                <InputControlled
                                    name="nb_passenger"
                                    label={`${t("form.nb_passenger")} *`}
                                    value={transfer?.nb_passenger}
                                    required={true}
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="col">
                            {passengers ? (
                                <>
                                    <div className="multi passengers">
                                        <div className="row row-md">
                                            <div className="col-12 col-md">
                                                <div className="multi-container">
                                                    {passengers.map(
                                                        (
                                                            passenger: any,
                                                            index: number
                                                        ) => (
                                                            <div
                                                                key={
                                                                    "passenger-" +
                                                                    index +
                                                                    "id-" +
                                                                    passenger.current_time
                                                                }
                                                                className="passenger"
                                                            >
                                                                <SubFormPassenger
                                                                    passenger={
                                                                        passenger
                                                                    }
                                                                    index={
                                                                        index
                                                                    }
                                                                    disabled={
                                                                        disabledForm
                                                                    }
                                                                    control={
                                                                        control
                                                                    }
                                                                    errors={
                                                                        errors
                                                                    }
                                                                    languages={
                                                                        languages
                                                                    }
                                                                    onDelete={(
                                                                        index: number
                                                                    ) =>
                                                                        deletePassenger(
                                                                            index
                                                                        )
                                                                    }
                                                                    onUpdate={(
                                                                        index: number,
                                                                        key: string,
                                                                        value: any
                                                                    ) =>
                                                                        updatePassenger(
                                                                            index,
                                                                            key,
                                                                            value
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-auto">
                                                <div className="multi-controls sub-passengers mgl4">
                                                    {!disabledForm && (
                                                        <Add
                                                            className="add"
                                                            onClick={() => {
                                                                setPassengers([
                                                                    ...passengers,
                                                                    new Passenger(),
                                                                ]);
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </FormSection>
                <FormSection
                    title={t("entity.transfer")}
                    className="mgt1"
                    marginTitle
                >
                    <div className="location_date_transfer_section">
                        {errorPostalType && (
                            <p style={{ color: "red", fontSize: "14px" }}>
                                {errorPostalType}
                            </p>
                        )}
                        {errorPriceType && (
                            <p style={{ color: "red", fontSize: "14px" }}>
                                {errorPriceType}
                            </p>
                        )}
                        <div className="location_date_transfer_part">
                            <div className="row row-md mb-3">
                                <div className="col">
                                    <div className="form-group">
                                        <InputControlled
                                            name="departure_location"
                                            label={`${t(
                                                "departure_location"
                                            )} *`}
                                            value={transfer?.departure_location}
                                            required={true}
                                            disabled={disabledForm}
                                            control={control}
                                            errors={errors}
                                        />
                                    </div>
                                </div> 
                                <div className="col">
                                    <div className="form-group">
                                        <InputControlled
                                            name="departure_postal_code"
                                            label={
                                                getValues("is_tender")
                                                    ? `${t(
                                                          "departure_postal_code"
                                                      )} *`
                                                    : t("departure_postal_code")
                                            }
                                            value={
                                                transfer?.departure_postal_code
                                            }
                                            required={getValues("is_tender")}
                                            disabled={disabledForm}
                                            control={control}
                                            errors={errors}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 d-block d-xl-none"></div>
                                <div className="col-auto col-xxl">
                                    <div className="form-group">
                                        {folder && (
                                            <InputDateTimeControlled
                                                name="departure_datetime"
                                                label={`${t(
                                                    "departure_date"
                                                )} *`}
                                                value={
                                                    transfer
                                                        ? formatDateTimePicker(
                                                              transfer?.departure_datetime
                                                          )
                                                        : folder
                                                        ? formatDateTimePicker(
                                                              folder.start_date
                                                          )
                                                        : null
                                                }
                                                disabled={disabledForm}
                                                control={control}
                                                errors={errors}
                                                required={true}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group">
                                        {routePoints ? (
                                            <>
                                                <div className="multi points col-12 col-xl-12">
                                                    <div className="multi-container">
                                                        {routePoints.map(
                                                            (
                                                                point: any,
                                                                index: number
                                                            ) => (
                                                                <div
                                                                    key={
                                                                        "point-" +
                                                                        index +
                                                                        "id-" +
                                                                        point.current_time
                                                                    }
                                                                    className="point"
                                                                >
                                                                    <SubFormPoint
                                                                        route_point={
                                                                            point
                                                                        }
                                                                        index={
                                                                            index
                                                                        }
                                                                        disabled={
                                                                            disabledForm
                                                                        }
                                                                        control={
                                                                            control
                                                                        }
                                                                        errors={
                                                                            errors
                                                                        }
                                                                        onDelete={(
                                                                            index: number
                                                                        ) =>
                                                                            deletePoint(
                                                                                index
                                                                            )
                                                                        }
                                                                        onUpdate={(
                                                                            index: number,
                                                                            key: string,
                                                                            value: any
                                                                        ) =>
                                                                            updatePoint(
                                                                                index,
                                                                                key,
                                                                                value
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                    <div className="multi-controls mgl4">
                                                        {!disabledForm && (
                                                            <Add
                                                                className="add"
                                                                onClick={() => {
                                                                    setRoutePoints(
                                                                        [
                                                                            ...routePoints,
                                                                            new RoutePoint(),
                                                                        ]
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="location_date_transfer_part">
                            <div className="row row-md">
                                <div className="col">
                                    <div className="form-group">
                                        <InputControlled
                                            name="arrival_location"
                                            label={`${t("arrival_location")} *`}
                                            value={transfer?.arrival_location}
                                            required={true}
                                            disabled={disabledForm}
                                            control={control}
                                            errors={errors}
                                        />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group">
                                        <InputControlled
                                            name="arrival_postal_code"
                                            label={
                                                getValues("is_tender")
                                                    ? `${t(
                                                          "arrival_postal_code"
                                                      )} *`
                                                    : t("arrival_postal_code")
                                            }
                                            value={
                                                transfer?.arrival_postal_code
                                            }
                                            required={getValues("is_tender")}
                                            disabled={disabledForm}
                                            control={control}
                                            errors={errors}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 d-block d-xl-none"></div>
                                <div className="col">
                                    <div className="form-group">
                                        {folder && (
                                            <InputDateTimeControlled
                                                name="arrival_datetime"
                                                label={`${t("arrival_date")} *`}
                                                value={
                                                    transfer
                                                        ? formatDateTimePicker(
                                                              transfer?.arrival_datetime
                                                          )
                                                        : folder
                                                        ? formatDateTimePicker(
                                                              folder.end_date
                                                          )
                                                        : null
                                                }
                                                disabled={disabledForm}
                                                control={control}
                                                errors={errors}
                                                required={true}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <CheckPermissions permissions={[Roles.ADMIN]}>
                            <div className="location_date_transfer_part mgl2 mgt4">
                                <div className="row row-md">
                                    <div className="col">
                                        <div className="form-group">
                                            <FormControlLabel
                                                className="switch-label-top mx-0"
                                                control={
                                                    <Switch
                                                        inputRef={register}
                                                        name="is_tender"
                                                        className="switch-ios"
                                                        onChange={() =>
                                                            setIsTender(
                                                                !isTender
                                                            )
                                                        }
                                                        checked={isTender}
                                                    />
                                                }
                                                label={t("form.is_tender")}
                                                labelPlacement="top"
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            {isTender && (
                                                <RadioGroup
                                                    style={{
                                                        flexDirection: "row",
                                                        flexWrap: "nowrap",
                                                    }}
                                                    aria-label="detail view"
                                                    name="view"
                                                    value={typeTender}
                                                    onChange={(event: any) =>
                                                        setTypeTender(
                                                            event.target.value
                                                        )
                                                    }
                                                >
                                                    <FormControlLabel
                                                        value="standard"
                                                        control={<Radio />}
                                                        label={t(
                                                            "form.standard"
                                                        )}
                                                    />
                                                    <FormControlLabel
                                                        value="fixed_price"
                                                        control={<Radio />}
                                                        label={t(
                                                            "form.fixed_price"
                                                        )}
                                                    />
                                                </RadioGroup>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="location_date_transfer_part mgl2">
                                <div className="row row-md">
                                    {isTender && typeTender === "standard" && (
                                        <>
                                            <div className="col-12 col-md">
                                                <div className="form-group">
                                                    <InputControlled
                                                        name="min_estimate"
                                                        label={`${t(
                                                            "form.min_estimate"
                                                        )} *`}
                                                        value={
                                                            transfer
                                                                ? transfer.min_estimate
                                                                : null
                                                        }
                                                        required={true}
                                                        disabled={disabledForm}
                                                        control={control}
                                                        errors={errors}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md">
                                                <div className="form-group">
                                                    <InputControlled
                                                        name="max_estimate"
                                                        label={`${t(
                                                            "form.max_estimate"
                                                        )} *`}
                                                        value={
                                                            transfer
                                                                ? transfer.max_estimate
                                                                : null
                                                        }
                                                        required={true}
                                                        disabled={disabledForm}
                                                        control={control}
                                                        errors={errors}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {isTender && typeTender === "fixed_price" && (
                                        <div className="col-12 col-md">
                                            <div className="form-group">
                                                <InputControlled
                                                    name="tender_price"
                                                    label={`${t(
                                                        "form.price"
                                                    )} *`}
                                                    value={
                                                        transfer
                                                            ? transfer.tender_price
                                                            : null
                                                    }
                                                    required={true}
                                                    disabled={disabledForm}
                                                    control={control}
                                                    errors={errors}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </CheckPermissions>
                    </div>
                </FormSection>

                <FormSection
                    title={t("form.miscellaneous")}
                    className="mgt1"
                    marginTitle
                >
                    <div className="row row-md">
                        <div className="col-12 col-md">
                            <div className="form-group">
                                <InputControlled
                                    name="infos"
                                    label={t("form.flight-train")}
                                    value={
                                        transfer?.infos ? transfer?.infos : ""
                                    }
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                    multiline={true}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md">
                            <div className="form-group">
                                <InputControlled
                                    name="free_note"
                                    label={t("note")}
                                    value={
                                        transfer?.free_note
                                            ? transfer?.free_note
                                            : ""
                                    }
                                    disabled={disabledForm}
                                    control={control}
                                    errors={errors}
                                    multiline={true}
                                />
                            </div>
                        </div>
                    </div>
                </FormSection>
                <FormSection
                    title={t("form.miscellaneous")}
                    className="mgt1"
                    marginTitle
                >
                    <div className="row row-md">
                        <div className="col-12 col-md">
                            <div className="form-group">
                                <AutocompleteControlled
                                    label={`${t("vehicle_types")} *`}
                                    name="vehicle_type"
                                    disabled={disabledForm}
                                    listItems={vehicleTypes}
                                    value={vehicleType}
                                    required={true}
                                    getOptionLabel={(type: VehicleType) =>
                                        type.label
                                    }
                                    control={control}
                                    errors={errors}
                                    onChange={(type: VehicleType) => {
                                        setVehicleType(type);
                                        setValue("vehicle_type_id", type.id);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </FormSection>

                <div className="form-group vehicles_options">
                    {vehicleOptions.map((option) => {
                        return (
                            <FormControlLabel
                                key={`form_vehicle_option_id_${option.id}`}
                                control={
                                    <Checkbox
                                        checked={hasOptionId(option.id)}
                                        value={hasOptionId(option.id)}
                                        disabled={disabledForm}
                                        onChange={(e) =>
                                            changeOption(
                                                option.id,
                                                e.target.value
                                            )
                                        }
                                        name="option_id"
                                    />
                                }
                                label={option.label}
                            />
                        );
                    })}
                </div>

                {!readOnly && (
                    <SubmitComponent
                        text={t("form.validate")}
                        isActivate={isActivateButton}
                    />
                )}

                <ErrorForm errorApi={errorApi} />
            </form>
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(FormTransfer);
