import React, { useState, useEffect } from "react";
import ContentSection from "../../components/content-section/ContentSection";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import TransferService from "../../services/transfer-service";
import { Transfer } from "../../models/transfer";
import FormTransfer from "./form-transfer/FormTransfer";
import FolderService from "../../services/folder-service";
import VehicleTypeService from "../../services/vehicle-type-service";
import VehicleOptionService from "../../services/vehicle-option-service";
import { Folder } from "../../models/folder";
import { VehicleType } from "../../models/vehicle-type";
import { VehicleOption } from "../../models/vehicle-option";
import { Language } from "../../models/language";
import LanguageService from "../../services/language-service";
import { formatDateDDMMYYYY } from "../../utils/DateHelper";

const TransferFormPage: React.FunctionComponent = (props) => {
    const { t } = useTranslation();
    const { id, folderId } = useParams();
    const [loading, setLoading] = useState(true);
    const [datas, setDatas] = useState<Transfer>();
    const [folder, setFolder] = useState<Folder>();
    const [folders, setFolders] = useState<Folder[]>([]);
    const [vehicleTypes, setVehicleTypes] = useState<VehicleType[]>([]);
    const [vehicleOptions, setVehicleOptions] = useState<VehicleOption[]>([]);
    const [languages, setLanguages] = useState<Language[]>([]);

    useEffect(() => {
        const fetchDatas = async () => {
            const vehicleTypeService = new VehicleTypeService();
            setVehicleTypes(await vehicleTypeService.all());

            const vehicleOptionService = new VehicleOptionService();
            setVehicleOptions(await vehicleOptionService.all());

            const languageService = new LanguageService();
            setLanguages(await languageService.all());

            if (folderId) {
                const folderService = new FolderService();
                setFolder(await folderService.get(folderId));
            }

            setLoading(true);
            if (id) {
                try {
                    const transferService = new TransferService();
                    const newData = await transferService.get(id);
                    setDatas(newData);

                    const folderService = new FolderService();
                    setFolder(
                        await folderService.get(Number(newData.folder_id))
                    );

                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    console.log("error", error);
                }
            } else {
                const folderService = new FolderService();
                let response = await folderService.all(null, null, "all");
                setFolders(response.data);
                setLoading(false)
            }
            if (folderId) {
                let transfer = new Transfer([]);
                transfer.folder_id = folderId;
                setDatas(transfer);
                setLoading(false)
            }
        };

        fetchDatas();
    }, [id, folderId]);

    return (
        <>
            <ContentSection
                title={
                    id
                        ? t("edit_transfer_on_folder", {
                              name: folder ? folder.code : "",
                              date: folder
                                  ? formatDateDDMMYYYY(folder.start_date)+' - '+formatDateDDMMYYYY(folder.end_date)
                                  : "",
                          })
                        : t("add_transfer_on_folder", {
                              name: folder ? folder.code : "",
                              date: folder
                                  ? formatDateDDMMYYYY(folder.start_date)+' - '+formatDateDDMMYYYY(folder.end_date)
                                  : "",
                          })
                }
                returnAction={true}
            >
                {!loading ? (
                    <FormTransfer
                        transfer={datas}
                        folderList={folders}
                        vehicleTypes={vehicleTypes}
                        vehicleOptions={vehicleOptions}
                        languages={languages}
                        folder={folder}
                        changeFolder={(folder) => setFolder(folder)}
                    />
                ) : (
                    <div>{t("loading")}</div>
                )}
            </ContentSection>
        </>
    );
};

export default TransferFormPage;
