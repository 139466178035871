import { Driver } from "./driver";
import { Deserializable } from "./interfaces/deserializable";
import { Vehicle } from "./vehicle";
import { VehicleOption } from "./vehicle-option";

export class TransferResponse implements Deserializable {
    public price: number;
    public tva: number;
    public assignment_id?: number;
    public driver_id?: number;
    public driver: Driver | null;
    public sms_detail: string;
    public options: TransferResponseOption[];
    public vehicle?: Vehicle;

    constructor(data: any = null) {
        this.price = data.price ? parseFloat(data?.price) : 0;
        this.tva = data.tva ? parseFloat(data.tva) : 0;
        this.options = data.options
            ? data.options.map(
                  (option: TransferResponseOption) =>
                      new TransferResponseOption(option)
              )
            : null;
        this.assignment_id = data?.assignment_id;
        this.driver_id = data.driver_id;
        this.driver = data.driver ? new Driver(data.driver) : null;
        this.sms_detail = data.sms_detail;
        this.vehicle =  data.vehicle ? new Vehicle(data.vehicle) : undefined;
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}

export class TransferResponseOption implements Deserializable {
    public id: number;
    public price: number;
    public tva: number;
    public current_time: number;
    public vehicle_option_id: number;
    public option?: VehicleOption;
    public label?: string;

    constructor(data: any = null) {
        this.id = data?.id;
        this.price = data?.price;
        this.tva = data?.tva;
        this.label = data?.label;
        this.vehicle_option_id = data?.vehicle_option_id;
        if (data.option) {
            this.option = new VehicleOption(data.option);
        }

        this.current_time =
            new Date().getTime() + Math.floor(Math.random() * Math.floor(9999));
    }
    deserialize(input: any): this {
        const transferResponse = Object.assign(this, input);
        if (input.option) {
            transferResponse.option = new VehicleOption(input.option);
        }

        return transferResponse;
    }
}
