import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "@material-ui/core";
import { useForm } from "react-hook-form";
import InputControlled from "../fields-controlled/InputControlled";
import Modal from "../modal/Modal";
import { Payment } from "../../models/payment";
import AutocompleteControlled from "../fields-controlled/AutocompleteControlled";
import SubmitComponent from "../../components/submit-component/SubmitComponent";
import "./PayentModal.scss";
import PaymentService from "../../services/payment-service";
import { toastr } from "react-redux-toastr";
import { ErrorFormData } from "../../models/interfaces/errorForm";
import { ErrorForm } from "../ErrorForm";
import { formatDatePicker } from "../../utils/DateHelper";
import InputDateControlled from "../fields-controlled/InputDateControlled";

interface IPopupAccept {
    isOpen: boolean;
    payment: Payment;
    closeDialog: () => void;
    refreshPayments: () => void;
    entity?: string;
}

interface IFormProps {
    amount: number;
    date: Date;
    method: string;
    reference: string;
    comment: string;
    type: string;
    no_notification?: boolean;
    folder_id: number;
    deposit_id: number;
}

const PaymentModal = ({
    isOpen,
    payment,
    closeDialog,
    refreshPayments,
    entity = "deposit",
}: IPopupAccept) => {
    const { t } = useTranslation();
    const { handleSubmit, errors, control, register } = useForm<IFormProps>();
    const [errorApi, setErrorApi] = useState(new ErrorFormData());
    const [disabledForm, setDisabledForm] = useState(false);
    const [isActivateButton, setIsActivateButton] = useState(true);
    const methods = ["bank_transfer", "credit_card", 'cheque', 'cash'];

    const onSubmit = async (data: any) => {
        setIsActivateButton(false);
        setDisabledForm(true);
        data.folder_id = payment.folder?.id;
        data.type = "payment";
        const service = new PaymentService();
        try {
            if (entity === "deposit") {
                data.deposit_id = payment.deposit?.id;
            }
            if (entity === "invoice") {
                data.invoice_id = payment.invoice?.id;
            }
            await service.add(data);
            toastr.success(t("success"), t("success_add_payment"));
            closeDialog();
            refreshPayments();
            setDisabledForm(false);
        } catch (error) {
            setDisabledForm(false);
            setErrorApi(new ErrorFormData(error.response.data));
        }
        setIsActivateButton(true);
    };
    return (
        <Modal
            label={t("payment")}
            isOpen={isOpen}
            closeModal={() => closeDialog()}
        >
            <div className="modal-payment">
                <div className="title-section">
                    <h2 className="title-content">
                        {entity === "deposit"
                            ? t("payment_deposit")
                            : t("payment_invoice")}
                        <span className="code">{payment.folder?.code}</span>
                    </h2>
                </div>
                <hr className="diviseur-payment"></hr>
                <form
                    className="form-react-payment row"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="form-part left col-6">
                        <div className="field-form">
                            <InputControlled
                                name="amount"
                                label={t("form.amount")}
                                value={payment.amount}
                                required={true}
                                disabled={disabledForm}
                                control={control}
                                errors={errors}
                            />
                        </div>
                        <div className="field-form">
                            <InputDateControlled
                                name="date"
                                label={t("form.payment_date")}
                                value={formatDatePicker(payment.date)}
                                disabled={disabledForm}
                                control={control}
                                errors={errors}
                                required={true}
                            />
                        </div>
                        <div className="field-form">
                            <AutocompleteControlled
                                label={t("form.method")}
                                name="method"
                                disabled={disabledForm}
                                listItems={methods}
                                value={
                                    payment.method ? payment.method : methods[0]
                                }
                                required={true}
                                getOptionLabel={(method: any) => t(method)}
                                control={control}
                                errors={errors}
                            />
                        </div>
                        <div className="field-form">
                            <Switch
                                inputRef={register}
                                name="no_notification"
                                className="switch-ios"
                                disabled={disabledForm}
                                defaultChecked={false}
                            />
                            <label
                                htmlFor="no_notification"
                                className="label-switch"
                            >
                                {t("form.no_notif")}
                            </label>
                        </div>
                    </div>
                    <div className="form-part right col-6">
                        <div className="field-form">
                            <InputControlled
                                name="reference"
                                label={t("form.reference")}
                                value={payment?.reference}
                                required={true}
                                disabled={disabledForm}
                                control={control}
                                errors={errors}
                                autoFocus={true}
                            />
                        </div>
                        <div className="field-form">
                            <InputControlled
                                name="comment"
                                label={t("form.comment")}
                                value={payment?.comment}
                                required={false}
                                disabled={disabledForm}
                                control={control}
                                errors={errors}
                            />
                        </div>
                        <div className="buttons-section">
                            <SubmitComponent
                                text={t("form.validate")}
                                isActivate={isActivateButton}
                            />
                        </div>
                    </div>
                </form>
            </div>
            <ErrorForm errorApi={errorApi} />
        </Modal>
    );
};

export default PaymentModal;
